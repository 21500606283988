import { Auth } from "aws-amplify";
import LoadingBar from "components/LoadingBar";
import {
  SET_CAM_ENV,
  SET_LOAD_CAM_NAME,
  SET_ORG_INFO,
  SET_USER_INFO,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useApiCall, useCustomEventsApiCall } from "hooks";
import { Login } from "pages/auth";
import ProjectList from "pages/projectList";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getOrganizationAPI, getUserAPI } from "services";
import { camNameValidation } from "utils";

export const RequiredAuth: React.FC = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [isAuth, setIsAuth] = useState(false);
  const loadingRef = useRef(true);
  const navigate = useNavigate();
  const [fetchCamEnvData, camEnvLoading, camEnvData, camEnvError] =
    useCustomEventsApiCall();
  const [getOrganization] = useApiCall(getOrganizationAPI);
  const [getUser, loading, userData] = useApiCall(getUserAPI);
  const checkAuth = async () => {
    try {
      loadingRef.current = true;
      const authenticatedUser = await Auth.currentAuthenticatedUser();

      if (authenticatedUser) {
        if (!state.camEnvironment && !state.camNameLoading) {
          dispatch({
            type: SET_LOAD_CAM_NAME,
            payload: true,
          });
          fetchCamEnvData("cam-environment-name", {}, 10000)
            .then((data: string) => {
              if (camNameValidation(data)) {
                dispatch({
                  type: SET_CAM_ENV,
                  payload: data,
                });
              }
            })
            .catch((err: Error) => {})
            .finally(() => {
              dispatch({
                type: SET_LOAD_CAM_NAME,
                payload: false,
              });
            });
        }
        const orgInformation = await getOrganization({
          userRecordId: authenticatedUser?.attributes?.sub,
          organizationId:
            authenticatedUser?.attributes?.["custom:organizationId"],
        });
        const userInformation = await getUser({
          userRecordId: authenticatedUser?.attributes?.sub,
          organizationId:
            authenticatedUser?.attributes?.["custom:organizationId"],
        });
        loadingRef.current = false;
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: {...authenticatedUser?.attributes, ...userInformation},
        });
        dispatch({
          type: SET_ORG_INFO,
          payload: orgInformation,
        });
      } else {
        loadingRef.current = false;
        setIsAuth(false);
        navigate("/login");
      }
    } catch (err) {
      loadingRef.current = false;
      setIsAuth(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loadingRef?.current) {
    return <LoadingBar />;
  }

  if (isAuth) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <Login />;
};

export const UnRequiredAuth: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  const checkAuth = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser) {
        navigate("/projects");
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: authenticatedUser?.attributes,
        });
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      setIsAuth(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (!isAuth) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <ProjectList />;
};
