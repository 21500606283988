import { CustomOrderModal } from "components/CustomOrderModal";
import FeatureInfoContent from "components/FeatureInfoToolTip";
import OrderDropDownMenu from "components/OrderDropDownMenu";
import TooltipIslandInfo from "components/TooltipIslandInfo";
import { SET_ASSIGNED_SETUPS } from "constant";
import { GlobalContext } from "context";
import { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Carousel } from "react-responsive-carousel";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { createDeepCopy } from "utils";
import FeatureInfoIcon from "../../../src/assets/images/icons/feature-info.svg";
import DragIcon from "../../../src/assets/images/icons/drag-icon-black.svg";
import DisplayCamSpecificFeatureName from "components/DisplayCamSpecificFeatureName";
import CamFeatureHightlightButton from "components/CamFeatureHighlightButton";

interface ISetupLevelOrder {
  setupId?: string;
}
function reorderArrayElement(
  originalArray: any[],
  currentIndex: number,
  destinationIndex: number
) {
  const arrayCopy = createDeepCopy(originalArray);
  const featureId = originalArray[currentIndex]?.featureId;
  if (currentIndex > destinationIndex) {
    for (let ptr = currentIndex - 1; ptr >= destinationIndex; ptr--) {
      if (arrayCopy[ptr]?.featureId === featureId) {
        toast.error("Cannot move this operation to the dragged position");
        return arrayCopy;
      }
    }
  } else {
    for (let ptr = currentIndex + 1; ptr <= destinationIndex; ptr++) {
      if (arrayCopy[ptr]?.featureId === featureId) {
        toast.error("Cannot move this operation to the dragged position");
        return arrayCopy;
      }
    }
  }
  const [removed] = arrayCopy.splice(currentIndex, 1);
  arrayCopy.splice(destinationIndex, 0, removed);
  return arrayCopy;
}
const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  ...(isDragging && { display: "table" }),
  ...draggableStyle,
});
const SetupLevelOrder: React.FC<ISetupLevelOrder> = ({ setupId = "" }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    machiningSetups,
    assignedMachiningSetups,
    featureMapping,
    projectInfo,
  } = state;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const handleReordering = (
    selectedId: string,
    type: number,
    position: number = 0
  ) => {
    const assignedMachiningSetupsCopy = createDeepCopy(assignedMachiningSetups);
    let draggableItems = assignedMachiningSetupsCopy[setupId];
    const currentIndex = draggableItems?.findIndex(
      (elem: any) =>
        `${elem.featureId}-${elem.passIndex}-${elem?.operationIndex}` ===
        selectedId
    );
    let destinationIndex;
    switch (type) {
      case 0:
        destinationIndex = 0;
        break;
      case 1:
        destinationIndex = Math.max(0, currentIndex - 1);
        break;
      case 2:
        destinationIndex = Math.min(
          draggableItems.length - 1,
          currentIndex + 1
        );
        break;
      case 3:
        destinationIndex = draggableItems.length - 1;
        break;
      case 4:
        destinationIndex = Math.min(
          Math.max(0, position),
          draggableItems.length - 1
        );
        break;
      default:
        return;
    }
    assignedMachiningSetupsCopy[setupId] = reorderArrayElement(
      assignedMachiningSetupsCopy[setupId],
      currentIndex,
      destinationIndex
    );
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: assignedMachiningSetupsCopy,
    });
  };
  const handleDragToIndex = (result: any) => {
    let assignedMachiningSetupsCopy: any = createDeepCopy(
      assignedMachiningSetups
    );
    assignedMachiningSetupsCopy[setupId] = reorderArrayElement(
      assignedMachiningSetupsCopy[setupId],
      result.source?.index,
      result.destination?.index ?? result.source?.index
    );
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: assignedMachiningSetupsCopy,
    });
  };
  return (
    <div className="flex flex-col gap-[8px] w-[100%]">
      <div className="flex items-center gap-[4px] text-gray-500 text-[12px] font-semibold py-[4px]">
        <img src={FeatureInfoIcon} alt="" />
        Please assign your features or feature-operations under the desired
        setups.
      </div>
      <div
        className="w-[100%] relative overflow-x-auto border border-gray-200 rounded-[12px] max-h-full "
        id="features-container"
      >
        <table
          className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          id="droppable"
        >
          <thead className="h-[44px] text-[12px] px-[8px] font-[500] py-[12px] text-gray-700 uppercase bg-white dark:text-gray-400 text-left">
            <tr>
              <th
                scope="col"
                className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
              >
                Feature Name
              </th>
              <th
                scope="col"
                className="colspan-2 px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
              >
                Tool path
              </th>
              <th
                scope="col"
                className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
              >
                Cutting pass
              </th>
              <th
                scope="col"
                className="colspan-2 px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
              >
                Operation
              </th>
              <th
                scope="col"
                className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
              >
                Tool type
              </th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleDragToIndex}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {assignedMachiningSetups?.[setupId]
                    ?.map((operation: any) => {
                      return {
                        ...operation,
                        id: `${operation.featureId}-${operation.passIndex}-${operation?.operationIndex}`,
                      };
                    })
                    ?.map((item: any, index: number) => {
                      const featureInformation =
                        machiningSetups?.[0]?.features[
                          featureMapping[item.featureId]?.index
                        ]?.featureInformation;
                      const islandInfo =
                        featureInformation?.feature_data?.feature_info
                          ?.island_info;
                      const passObject =
                        featureInformation?.strategies?.[0]
                          ?.machining_strategy?.[item.strategyIndex]?.passes[
                          item.passIndex
                        ];
                      const selectedToolPath =
                        passObject?.operations?.[item.operationIndex]
                          ?.tool_paths?.[
                          passObject?.operations?.[item.operationIndex]
                            ?.selectedToolPathInd ?? 0
                        ];
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <tr
                              className="bg-gray-50 py-[8px] px-[16px] border-t border-gray-200  text-[14px] font-semibold text-gray-700"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <td className="px-[8px] py-[16px] w-[240px] lg:w-[300px] xl:w-[400px]">
                                <div className="flex gap-4">
                                  <div className="inline-flex flex-row items-center justify-center">
                                    <img
                                      src={DragIcon}
                                      alt=""
                                      className="w-[30px] min-w-[30px]"
                                    />
                                  </div>
                                  <div className="inline-flex items-center gap-[4px] whitespace-nowrap max-w-[240px]">
                                    {featureInformation?.tag_id ? (
                                      <CamFeatureHightlightButton
                                        label={
                                          featureMapping[item.featureId]?.title
                                        }
                                        tagId={featureInformation.tag_id}
                                        tooltipContainer="features-container"
                                      />
                                    ) : (
                                      featureMapping[item.featureId]?.title
                                    )}
                                    <DisplayCamSpecificFeatureName
                                      namesArray={
                                        featureInformation?.cam_specific_names
                                      }
                                    />{" "}
                                    <img
                                      src={FeatureInfoIcon}
                                      alt=""
                                      id={`feature-${item.featureId}${item.operationIndex}`}
                                    />{" "}
                                  </div>
                                  <Tooltip
                                    anchorSelect={`#feature-${item.featureId}${item.operationIndex}`}
                                    style={{
                                      backgroundColor: "#D1E9FF",
                                      color: "#344054",
                                      boxShadow:
                                        "1px 0px 4px rgba(0, 0, 0, .15), 0px 1px 4px rgba(0, 0, 0, .25)",
                                      width: "240px",
                                      padding: "8px",
                                      borderRadius: "4px",
                                    }}
                                    place={"bottom"}
                                    positionStrategy="fixed"
                                    clickable
                                  >
                                    {!islandInfo?.islands?.length ? (
                                      <FeatureInfoContent
                                        feature_info={
                                          featureInformation?.feature_data
                                            ?.feature_info
                                        }
                                        feature_name={
                                          featureInformation?.feature_data
                                            ?.feature_name
                                        }
                                        measurement_unit={projectInfo?.unit}
                                      />
                                    ) : (
                                      <Carousel
                                        showArrows={false}
                                        showThumbs={false}
                                        showIndicators={true}
                                        showStatus={false}
                                        transitionTime={0}
                                      >
                                        <FeatureInfoContent
                                          feature_info={
                                            featureInformation?.feature_data
                                              ?.feature_info
                                          }
                                          feature_name={
                                            featureInformation?.feature_data
                                              ?.feature_name
                                          }
                                          measurement_unit={projectInfo?.unit}
                                        />
                                        {islandInfo?.islands?.length &&
                                          islandInfo?.islands?.map?.(
                                            (islandData: any, idx2: number) => {
                                              return (
                                                <TooltipIslandInfo
                                                  key={`${item.featureId}${item.operationIndex}${idx2}`}
                                                  island={islandData}
                                                  index={idx2}
                                                  unit={" " + projectInfo?.unit}
                                                ></TooltipIslandInfo>
                                              );
                                            }
                                          )}
                                      </Carousel>
                                    )}
                                  </Tooltip>
                                </div>
                              </td>
                              <td className="px-[8px] py-[16px]">
                                <div>
                                  {`${selectedToolPath?.tool_path} (${
                                    selectedToolPath?.tool_path_style ?? "N/A"
                                  })`}
                                </div>
                              </td>
                              <td className="px-[8px] py-[16px] ">
                                <div>{passObject?.pass}</div>
                              </td>
                              <td className="px-[8px] py-[16px] ">
                                <div>
                                  {
                                    passObject?.operations?.[
                                      item.operationIndex
                                    ]?.operation
                                  }
                                  {`(${
                                    passObject?.operations?.[
                                      item.operationIndex
                                    ]?.location
                                  })`}
                                </div>
                              </td>
                              <td className="px-[8px] py-[16px] ">
                                <div className="w-[100%] flex flex-row justify-between">
                                  <div>
                                    {
                                      selectedToolPath?.tool_types?.[
                                        passObject?.operations?.[
                                          item.operationIndex
                                        ]?.selectedToolTypeInd ?? 0
                                      ]
                                    }
                                  </div>
                                  {assignedMachiningSetups[setupId]?.length >
                                    1 && (
                                    <OrderDropDownMenu
                                      item={{
                                        id: item.id,
                                        title:
                                          featureMapping[item.featureId]?.title,
                                        operation:
                                          passObject?.operations?.[
                                            item.operationIndex
                                          ]?.operation,
                                        tool_path: selectedToolPath?.tool_path,
                                        tool_path_type:
                                          selectedToolPath?.tool_path_type,
                                        tool_path_style:
                                          selectedToolPath?.tool_path_style,
                                      }}
                                      index={index}
                                      setOpen={setOpen}
                                      setModalData={setModalData}
                                      handleReordering={handleReordering}
                                      lastIndex={
                                        assignedMachiningSetups[setupId]
                                          ?.length - 1
                                      }
                                    ></OrderDropDownMenu>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      <CustomOrderModal
        open={open}
        setOpen={setOpen}
        data={modalData}
        onConfirm={handleReordering}
      ></CustomOrderModal>
    </div>
  );
};

export default SetupLevelOrder;
