import PopUpCross from "assets/images/icons/charm_cross.svg";
import Modal from "react-modal";
import { IAdditionalToolParamsModal, POPUP_STYLES } from "./utils";

// sample object for tool_path_parameters:
// {
//     Tool: {
//          "Feed and Speeds": {
//              "Spindle Speed": {
//                  "value": 1961.0,
//                  "unit": "rpm"
//              },
//              "Surface Speed": {
//                  "value": 1.452,
//                  "unit": "mm/min"
//              },
//              "Cutting Feedrate": {
//                  "value": 5.748,
//                  "unit": "mm/min"
//              },
//          }
// }

const AdditionalToolParamsModal: React.FC<IAdditionalToolParamsModal> = ({
  popUpOpen,
  handleClose,
  tool,
}) => {
  return (
    <Modal isOpen={popUpOpen} onRequestClose={handleClose} style={POPUP_STYLES}>
      <div>
        <div className="flex flex-row justify-between border-b border-gray-300 p-[16px] sticky top-0 bg-white">
          <span className="font-medium text-[20px] leading-6">
            {tool?.["Tool ID"] || "N/A"}
          </span>
          <div className="flex flex-col justify-center">
            <button
              onClick={handleClose}
              className="inline-block w-[20px] h-[20px]"
            >
              <img src={PopUpCross} alt="cross" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[50vh] overflow-y-auto pb-4">
          <div className="px-4 pt-1">
            <table className="w-full text-left text-[10px]" aria-hidden="true">
              <tbody>
                <tr>
                  <td className="px-2 text-gray-600 font-medium !w-[50%]">
                    Advance Per Tooth
                  </td>
                  <td className="text-gray-700 font-bold px-2 break-words">
                    {tool?.["Advance per tooth"]
                      ? `${tool["Advance per tooth"].value} ${tool["Advance per tooth"].unit}`
                      : "--"}
                  </td>
                </tr>
                <tr>
                  <td className="px-2 text-gray-600 font-medium !w-[50%]">
                    Advance Per Revolution
                  </td>
                  <td className="px-2 text-gray-700 font-bold break-words">
                    {tool?.["Advance per rev"]
                      ? `${tool["Advance per rev"].value} ${tool["Advance per rev"].unit}`
                      : "--"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {!!Object.keys(tool?.tool_path_parameters ?? {}).length && (
            <div className="flex px-4 pt-1">
              <div className="w-full border border-gray-200 rounded-lg overflow-hidden">
                <table className="w-full rounded-lg">
                  {Object.keys(tool.tool_path_parameters).map(
                    (section: string) => {
                      return (
                        <>
                          <thead>
                            <tr>
                              <th
                                colSpan={2}
                                className="text-left bg-gray-200 text-gray-700 px-[8px] py-[2px] text-[12px] font-bold border border-color-100"
                              >
                                {section}
                              </th>
                            </tr>
                          </thead>
                          {Object.keys(
                            tool.tool_path_parameters[section] ?? {}
                          ).map((subsection: string) => {
                            return (
                              <tbody key={`${tool?.["Tool ID"]}-${subsection}`}>
                                <tr>
                                  <td
                                    className="text-gray-700 px-[8px] py-[2px] border border-color-100 w-[50%] text-[10px] text-gray-600 font-bold"
                                    colSpan={2}
                                  >
                                    {subsection}
                                  </td>
                                </tr>
                                {Object.keys(
                                  tool.tool_path_parameters[section][
                                    subsection
                                  ] ?? {}
                                ).map((key: string) => {
                                  const param =
                                    tool.tool_path_parameters[section][
                                      subsection
                                    ][key];
                                  return (
                                    <tr
                                      className=""
                                      key={`${tool?.["Tool ID"]}-${subsection}-${key}`}
                                    >
                                      <td className="text-gray-700 px-[8px] py-[2px] border border-color-100 w-[50%] text-[10px] text-gray-600 font-medium">
                                        {key}
                                      </td>
                                      <td className="text-gray-700 px-[8px] py-[2px] border border-color-100 w-[50%] text-gray-700 font-bold text-[10px]">
                                        {param.value?.toString() ?? "--"}
                                        {param.unit ? ` ${param.unit}` : ""}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            );
                          })}
                        </>
                      );
                    }
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalToolParamsModal;
