type CustomCheckboxIconProps = {
  isCheck: boolean;
  notAll: boolean;
  disabled?: boolean;
  darkBorder?: boolean;
};

const CustomCheckboxIcon: React.FC<CustomCheckboxIconProps> = ({
  isCheck,
  notAll,
  disabled,
  darkBorder,
}) => {
  let fillColor = "white";
  let strokeColor = darkBorder ? "#98A2B3" : "#D0D5DD";

  if (isCheck) {
    fillColor = "#F0F9FF";
    strokeColor = "#0086C9";
  }

  const path =
    isCheck && !notAll ? (
      <path
        d="M14.6693 6.5L8.2526 12.9167L5.33594 10"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ) : isCheck && notAll ? (
      <path
        d="M5.91406 10.5H14.0807"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ) : null;

  return (
    <svg
      width="20"
      height={isCheck && notAll ? "21" : "20"}
      viewBox="0 0 20 20"
      className="flex-shrink-0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y={isCheck && notAll ? "1" : "0.5"}
        width="19"
        height="19"
        rx="5.5"
        fill={disabled ? "#F2F2F2" : fillColor}
      />
      <rect
        x="0.5"
        y={isCheck && notAll ? "1" : "0.5"}
        width="19"
        height="19"
        rx="5.5"
        stroke={strokeColor}
      />
      {path}
    </svg>
  );
};

export default CustomCheckboxIcon;
