import { useContext, useState, useEffect, useCallback } from "react";
import AccordionItem from "components/AccordionItem";
import { Checkbox } from "components/Checkbox";
import MachiningStrategySummaryBox from "components/MachiningStrategySummaryBox";
import SummaryBox from "components/SummaryBox";
import { GlobalContext } from "context/GlobalContext";
import {
  ACTION_BY_KEY,
  keyList,
  SET_ASSIGNED_SETUPS,
  SET_FEATURE_MAPPING,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SET_STREAM_STEP,
  SET_VISIBLE_IDS,
  SET_VISITED_FEATURES,
  SET_VISITED_STEPS,
} from "constant";
import { useApiCall, useLocalSiemensNxApiCall } from "hooks";
import {
  getMachiningStrategy,
  postCreateUserSelectionAPI,
  putProjectAPI,
  putUserSelectionAPI,
} from "services";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { FiArrowRight, FiPlus } from "react-icons/fi";
import NoFoundImg from "assets/images/NoFound.svg";
import { constructTitle } from "../MachiningStrategySummary/utils";
import CreateStrategyModal from "components/CreateStrategyModal";
import { toast } from "react-toastify";
import { createDeepCopy, notifyCAM, resetScroll } from "utils";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import UnvisitedFeaturesModal from "components/UnvisitedFeaturesModal";
import DisplayCamSpecificFeatureName from "components/DisplayCamSpecificFeatureName";

const MachineStrategy = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    showTooltip,
    hideTooltip,
    projectInfo,
    setupNumber,
    machiningSetups,
    visiblefeature_ids,
    originMachiningSetups,
    assignedMachiningSetups,
    visitedFeatures,
    stepsVisitedStatus,
  } = state;
  const navigate = useNavigate();
  const [putProject] = useApiCall(putProjectAPI);
  const [postCreateUserSelection]: any = useApiCall(postCreateUserSelectionAPI);
  const [putUserSelection]: any = useApiCall(putUserSelectionAPI);
  const [selectedItem, setSelectedItem] = useState<any>({
    type: null,
    feature_id: null,
  });
  const [selectedTitle, setSelectedTitle] = useState<any>(null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedSummary, setSelectedSummary] = useState<any>(null);
  const [fetchData, loading]: any = useApiCall(getMachiningStrategy);
  const [data, setData] = useState<any>(null);
  const [availableList, setAvailableList] = useState([]);
  const [selectedFeaturesSet, setSelectedFeaturesSet] = useState<Set<string>>(
    new Set([])
  );
  const [isFirst, setIsFirst] = useState(true);
  const [showCreateStrategyModal, setShowCreateStrategyModal] = useState(false);
  const [showUnvisitedFeaturesModal, setShowUnvisitedFeaturesModal] =
    useState(false);
  const [unvisitedFeatures, setUnvisitedFeatures] = useState<string[]>([]);
  const [fetchSiemensNxData] = useLocalSiemensNxApiCall();

  useEffect(() => {
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    if (setupIdx < 0 || !visiblefeature_ids?.["1"]?.length) return;

    let arr: any = [];
    keyList?.forEach(({ title, sectionTitle, type }, idx: number) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      existing_features?.forEach((feature: any, idx1: number) => {
        if (!visiblefeature_ids?.["1"]?.includes(feature?.feature_id)) {
          return;
        }
        detailData.push({
          title: `${title} ${idx1 + 1}`,
          index: idx1,
          feature_id: feature?.feature_id,
          featureInformation: feature?.featureInformation,
        });
      });

      if (detailData?.length) {
        arr.push({
          title,
          sectionTitle,
          type,
          data: detailData,
        });
      }
    });
    if (isFirst && !!arr?.length) {
      setSelectedItem({
        type: arr?.[0]?.type,
        feature_id: arr?.[0]?.data?.[0]?.feature_id,
        tag_id: arr?.[0]?.data?.[0]?.featureInformation?.tag_id,
      });
      setSelectedTitle(arr?.[0]?.data?.[0]?.title);
      const selectedSet = new Set<string>();
      arr?.forEach((category: any) => {
        category?.data?.forEach((feature: any) => {
          const { feature_id, featureInformation } = feature;
          if (
            featureInformation?.strategies?.some((strategy: any) =>
              strategy?.machining_strategy?.some(
                (mach: any) => mach?.isSelected
              )
            )
          ) {
            selectedSet.add(feature_id);
          }
        });
      });
      setSelectedFeaturesSet(selectedSet);
      setIsFirst(false);
    }

    setAvailableList(arr);
  }, [machiningSetups, visiblefeature_ids]);

  useEffect(() => {
    if (!selectedItem?.feature_id) return;

    if (selectedItem.tag_id) {
      notifyCAM("highlight-feature", { tag_id: selectedItem.tag_id });
    }

    if (!visitedFeatures.strategy.has(selectedItem.feature_id)) {
      const strategyVisitedFeatures = visitedFeatures.strategy;
      strategyVisitedFeatures.add(selectedItem.feature_id);
      dispatch({
        type: SET_VISITED_FEATURES,
        payload: {
          ...visitedFeatures,
          strategy: strategyVisitedFeatures,
        },
      });
    }
    let updated_setups = [...machiningSetups];
    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    let feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    const originaSetupIdx = originMachiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const originfeature_idx = originMachiningSetups?.[
      originaSetupIdx
    ]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    const origin_feature_infomation = {
      ...originMachiningSetups?.[originaSetupIdx]?.features?.[originfeature_idx]
        ?.featureInformation,
    };

    let flag = false;
    if (
      !feature_information?.strategies?.[0]?.machining_strategy ||
      feature_information?.feature_data?.feature_name !==
        origin_feature_infomation?.feature_data?.feature_name ||
      feature_information?.feature_data?.feature_type !==
        origin_feature_infomation?.feature_data?.feature_type ||
      JSON.stringify(origin_feature_infomation?.feature_data?.feature_info) !==
        JSON.stringify(feature_information?.feature_data?.feature_info)
    ) {
      flag = true;
    }
    feature_information?.strategies?.[0]?.machining_strategy?.forEach(
      (strategy: any) => {
        if (strategy?.["isValid"] === undefined) flag = true;
      }
    );

    if (flag) {
      fetchData([
        {
          ...feature_information?.feature_data,
          measurement_unit: projectInfo?.unit,
          cam_software: projectInfo?.cam_software ?? "siemens",
        },
      ]).then((res: any) => {
        // initialize steps visited status for this feature to an empty set
        stepsVisitedStatus[selectedItem.feature_id] = {
          visited: new Set([]),
          lastVisitedPassIndex: 0,
          lastVisitedOperationIndex: 0,
        };
        dispatch({
          type: SET_VISITED_STEPS,
          payload: stepsVisitedStatus,
        });

        if (!res) {
          feature_information["strategies"] = [
            {
              machining_strategy: [],
              message:
                "Error while searching strategies for given feature information.",
            },
          ];
          updated_setups[setupIdx].features[feature_idx].featureInformation =
            feature_information;
          dispatch({
            type: SET_MACHINE_SETUP,
            payload: updated_setups,
          });
          setData(null);
          return;
        }

        let new_strategy = [
          ...(res?.strategies?.[0]?.machining_strategy ?? []),
        ];

        new_strategy?.forEach((strategy: any) => {
          strategy?.passes?.forEach((pass: any) => {
            pass?.operations?.forEach((operation: any) => {
              operation["isValid"] = true;
              operation["feedback"] = "";
            });
          });
        });

        new_strategy = new_strategy?.map((item: any, idx: number) => {
          if (idx === 0) {
            return { ...item, isSelected: true, isValid: true, feedback: "" };
          }
          return { ...item, isSelected: false, isValid: true, feedback: "" };
        });
        res.strategies[0].machining_strategy = new_strategy;
        feature_information["strategies"] = res.strategies;
        updated_setups[setupIdx].features[feature_idx].featureInformation =
          feature_information;

        if (new_strategy?.[0]?.isSelected) {
          setSelectedFeaturesSet((selectedSet: Set<string>) => {
            selectedSet.add(selectedItem?.feature_id);
            return selectedSet;
          });
        }

        dispatch({
          type: SET_MACHINE_SETUP,
          payload: updated_setups,
        });
        setData(res);
      });
    } else {
      setData({ strategies: feature_information?.strategies });
    }
  }, [selectedItem]);

  const addCustomStrategy = useCallback(
    (strategy: any) => {
      const updated_setups = [...machiningSetups];
      const setupIdx = updated_setups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );
      const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
        (feature: any) => feature?.feature_id === selectedItem?.feature_id
      );
      const feature_information = {
        ...updated_setups?.[setupIdx]?.features?.[feature_idx]
          ?.featureInformation,
      };

      let customStrategyCount = 0;
      feature_information?.strategies?.[0]?.machining_strategy?.forEach(
        (strategy: any) => {
          if (strategy.custom) {
            customStrategyCount++;
          }
        }
      );

      if (customStrategyCount >= 5) {
        toast.error("You can not add more than 5 custom strategies");
        return;
      }

      feature_information?.strategies?.[0]?.machining_strategy?.unshift(
        strategy
      );

      /**
       * Once a new strategy is added, the index of the existing strategy will
       * be incremented by one. If there is already a selected strategy,
       * we will update the assignedMachiningSetups mapping accordingly.
       */
      const selectedStrategy =
        feature_information?.strategies?.[0]?.machining_strategy?.find(
          (strategy: any) => strategy?.isSelected
        );
      if (selectedStrategy) {
        const updatedAssignedSetups = createDeepCopy(assignedMachiningSetups);
        selectedStrategy.passes?.forEach((pass: any) => {
          pass.operations?.forEach((operation: any) => {
            if (operation.assignedSetupId) {
              updatedAssignedSetups[operation.assignedSetupId][
                operation.pass_number
              ].strategyIndex += 1;
            }
          });
        });
        dispatch({
          type: SET_ASSIGNED_SETUPS,
          payload: updatedAssignedSetups,
        });
      }
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updated_setups,
      });
      setData({ strategies: feature_information?.strategies });
    },
    [
      dispatch,
      machiningSetups,
      selectedItem?.feature_id,
      setupNumber,
      assignedMachiningSetups,
    ]
  );

  useEffect(() => {
    dispatch({
      type: SET_LOADING_BAR,
      payload: loading,
    });
  }, [dispatch, loading]);

  useEffect(() => {
    if (!data) return;
    let selected = data?.strategies?.[0]?.machining_strategy?.find(
      (strategy: any) => strategy?.isSelected
    );
    setSelectedSummary(selected);
    resetScroll("strategies-container", "scrollLeft");
    resetScroll("feature-container", "scrollTop");
  }, [data]);

  const confirmValidData = (strategy: any, index: number) => {
    let updated_setups = [...machiningSetups];
    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === selectedItem?.feature_id
    );

    let feature_information = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation,
    };

    feature_information.strategies[0].machining_strategy[index].isValid =
      strategy.isValid;
    feature_information.strategies[0].machining_strategy[index].feedback =
      strategy.feedback;

    feature_information.strategies[0].machining_strategy[index].passes?.forEach(
      (pass: any, ind: number) => {
        pass?.operations?.forEach((operation: any, ind2: number) => {
          operation["isValid"] =
            strategy?.passes?.[ind]?.operations?.[ind2]?.isValid;
          operation["feedback"] =
            strategy?.passes?.[ind]?.operations?.[ind2]?.feedback;
        });
      }
    );
    updated_setups[setupIdx].features[feature_idx].featureInformation =
      feature_information;
    if (strategy?.isSelected && !strategy?.isValid) {
      feature_information.strategies[0].machining_strategy[index].isSelected =
        false;
      setSelectedSummary({});
      setSelectedFeaturesSet((selectedSet: Set<string>) => {
        selectedSet.delete(selectedItem?.feature_id);
        return selectedSet;
      });

      // Initialize visited steps to an empty set when selected strategy is marked invalid
      stepsVisitedStatus[selectedItem.feature_id] = {
        visited: new Set([]),
        lastVisitedPassIndex: 0,
        lastVisitedOperationIndex: 0,
      };
      dispatch({
        type: SET_VISITED_STEPS,
        payload: stepsVisitedStatus,
      });
    }

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  const handleRedirect = (tab: string) => {
    navigate(`/project?tab=${tab}`, {
      state: { referrer: "machine" },
    });
  };

  const makeFeatureMapping = () => {
    const featureTypeToCountMap: any = {};
    const featureTypeToTitleMap: any = {};
    keyList.forEach((element: any) => {
      featureTypeToTitleMap[element.type] = element.title;
    });
    const featureMapping: any = {};
    machiningSetups?.[0]?.features?.forEach((feature: any, index: number) => {
      const type = feature?.featureInformation?.feature_data?.feature_type;
      if (type in featureTypeToCountMap) {
        featureTypeToCountMap[type]++;
      } else {
        featureTypeToCountMap[type] = 1;
      }
      const title = constructTitle(
        type,
        featureTypeToTitleMap,
        featureTypeToCountMap
      );
      featureMapping[feature?.feature_id] = {
        index,
        title,
        serialNumber: featureTypeToCountMap[type],
      };
    });
    dispatch({
      type: SET_FEATURE_MAPPING,
      payload: featureMapping,
    });
  };

  const handleContinue = async () => {
    makeFeatureMapping();
    const featureList: string[] = [];
    const visitedSet = visitedFeatures.strategy;
    availableList.forEach((item: any) => {
      item.data.forEach((feature: any) => {
        if (!visitedSet.has(feature.feature_id)) {
          featureList.push(feature.title);
        }
      });
    });

    if (
      JSON.stringify(machiningSetups) !== JSON.stringify(originMachiningSetups)
    ) {
      /**
       * If there is a change deteched in the machining setups we reset any
       * toolpath preview that user may have created.
       * Currently we treat it as a fire and forget trigger, so no need
       * for error handling.
       * This is currently only enabled for siemens cam env.
       */
      if (projectInfo.cam_software === "siemens") {
        try {
          fetchSiemensNxData("reset_tool_paths", {});
        } catch {}
      }
    }

    if (featureList.length) {
      setUnvisitedFeatures(featureList);
      setShowUnvisitedFeaturesModal(true);
    } else {
      dispatch({
        type: SET_VISIBLE_IDS,
        payload: {
          pageMapping: "0",
          ids: Array.from(selectedFeaturesSet),
        },
      });
      handleRedirect("manufacturing-process");
    }
    // try {
    //   const payload: any = {
    //     organizationId: projectInfo?.organizationId,
    //     streamStep: "initial-trigger",
    //     selectionId: projectInfo?.userSelectionId,
    //     ncProjectId: projectInfo?.ncProjectId,
    //     userRecordId: projectInfo?.userRecordId,
    //     machineId: "",
    //     machineType: "",
    //     workpieceCADfile: "",
    //     workpieceId: "",
    //     workpiecePathToCADfile: "",
    //     workpieceMaterial: projectInfo?.material,
    //     workpieceSubMaterial: projectInfo?.subMaterial,
    //     workpieceRawDimensions: {
    //       length: "",
    //       width: "",
    //       height: "",
    //     },
    //     machiningSetups,
    //   };
    //   if (projectInfo?.userSelectionId) {
    //     if (
    //       JSON.stringify(machiningSetups) ===
    //       JSON.stringify(originMachiningSetups)
    //     ) {
    //       dispatch({
    //         type: SET_ORIGIN_MACHINE_SETUP,
    //         payload: [...machiningSetups],
    //       });
    //       handleRedirect("strategy-summary");
    //     } else {
    //       dispatch({
    //         type: SET_LOADING_BAR,
    //         payload: true,
    //       });
    //       putUserSelection(payload).finally(() => {
    //         dispatch({
    //           type: SET_LOADING_BAR,
    //           payload: false,
    //         });
    //         dispatch({
    //           type: SET_ORIGIN_MACHINE_SETUP,
    //           payload: [...machiningSetups],
    //         });
    //         handleRedirect("strategy-summary");
    //       });
    //     }
    //   } else {
    //     dispatch({
    //       type: SET_LOADING_BAR,
    //       payload: true,
    //     });
    //     postCreateUserSelection(payload)
    //       .then(async (res: any) => {
    //         const putProjectPayload = {
    //           organizationId: projectInfo?.organizationId,
    //           ncProjectId: projectInfo?.ncProjectId,
    //           userRecordId: projectInfo?.userRecordId,
    //           userSelectionId: res?.selectionId,
    //         };
    //         await putProject(putProjectPayload);
    //         dispatch({
    //           type: SET_PROJECT_INFO,
    //           payload: {
    //             ...projectInfo,
    //             userSelectionId: res?.selectionId,
    //           },
    //         });
    //         dispatch({
    //           type: SET_STREAM_STEP,
    //           payload: "intial-trigger",
    //         });
    //         dispatch({
    //           type: SET_ORIGIN_MACHINE_SETUP,
    //           payload: [...machiningSetups],
    //         });
    //       })
    //       .finally(() => {
    //         dispatch({
    //           type: SET_LOADING_BAR,
    //           payload: false,
    //         });
    //         handleRedirect("strategy-summary");
    //       });
    //   }
    // } catch (err) {
    //   dispatch({
    //     type: SET_LOADING_BAR,
    //     payload: false,
    //   });
    // }
  };

  if (!availableList?.length) {
    navigate("/projects");
  }

  return (
    <div className="relative h-full flex px-4">
      <div className="w-[20%] h-full">
        <div className="w-full overflow-y-auto h-full flex flex-col justify-between bg-gray-f9f p-1 rounded-md border border-gray-eac">
          <div className="w-full">
            <p className="font-medium text-gray-475 text-[10px]">
              Features Identified
            </p>
            <div className="pr-1">
              {availableList?.map(
                ({ sectionTitle, data, type }: any, idx: number) => {
                  return (
                    <AccordionItem
                      key={idx}
                      tabLabelChild={sectionTitle}
                      className="border-2 border-surface-soft py-2 px-4 my-1"
                      labelChildClass="text-black-222"
                      tabContentClassName="!pb-0"
                      checked={selectedCard === idx}
                      dragIcon={false}
                      onChange={(e) => {
                        if (selectedCard !== idx) {
                          setSelectedCard(idx);
                          setSelectedItem({
                            type,
                            feature_id: data?.[0]?.feature_id,
                            tag_id: data?.[0]?.featureInformation?.tag_id,
                          });
                          setSelectedTitle(data?.[0]?.title);
                        }
                      }}
                      count={
                        data?.filter(
                          (item: any) =>
                            selectedFeaturesSet.has(item?.feature_id) &&
                            !!item?.featureInformation?.strategies?.find(
                              (strategy: any) =>
                                !!strategy?.machining_strategy?.find(
                                  (item3: any) => item3?.isSelected
                                )
                            )
                        )?.length || 0
                      }
                      isBackground
                    >
                      <>
                        {data?.map((item: any, idx1: number) => {
                          return (
                            <div
                              key={idx1}
                              className="mb-[10px] flex flex-row items-center justify-between w-full overflow-hidden gap-[4px]"
                            >
                              <div className="flex flex-row items-center w-full overflow-hidden">
                                {selectedItem?.type === type &&
                                  selectedItem?.index === idx1 && (
                                    <FiArrowRight className="text-surface-default mr-2" />
                                  )}
                                <button
                                  className={clsx(
                                    "text-sm",
                                    "truncate",
                                    "inline-flex",
                                    {
                                      "text-surface-default font-semibold overflow-hidden text-left":
                                        selectedItem?.feature_id ===
                                        item?.feature_id,
                                    }
                                  )}
                                  onClick={() => {
                                    if (
                                      selectedItem?.feature_id !==
                                      item?.feature_id
                                    ) {
                                      setSelectedItem({
                                        type,
                                        feature_id: item?.feature_id,
                                        tag_id:
                                          item?.featureInformation?.tag_id,
                                      });
                                      setSelectedTitle(`${item?.title}`);
                                    }
                                  }}
                                >
                                  {`${item?.title}`}
                                  <DisplayCamSpecificFeatureName
                                    namesArray={
                                      item.featureInformation
                                        ?.cam_specific_names
                                    }
                                  />
                                </button>
                              </div>
                              {!!item?.featureInformation?.strategies?.find(
                                (strategy: any) =>
                                  !!strategy?.machining_strategy?.find(
                                    (item3: any) => item3?.isSelected
                                  )
                              ) && (
                                <div className="flex-1 w-[16px] min-w-[16px] ">
                                  <Checkbox
                                    type="yellow"
                                    checked={selectedFeaturesSet.has(
                                      item?.feature_id
                                    )}
                                    onChange={() => {
                                      const selectedSet = new Set(
                                        selectedFeaturesSet
                                      );
                                      if (selectedSet.has(item?.feature_id)) {
                                        selectedSet.delete(item?.feature_id);
                                      } else {
                                        selectedSet.add(item?.feature_id);
                                      }
                                      setSelectedFeaturesSet(selectedSet);
                                      setAvailableList([...availableList]);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </>
                    </AccordionItem>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedItem?.feature_id && (
        <div className="w-[80%] flex flex-col relative">
          <div
            className="w-full px-4 h-[calc(100%-75px)] overflow-y-auto"
            id="feature-container"
            onScroll={() => hideTooltip()}
          >
            <div className="rounded flex items-center gap-2 text-gray-600 bg-surface-secondary border border-blue-d1e text-[14px] py-1 px-2 mb-4">
              <img src={FeatureInfoIcon} alt="" width="24" />
              Ensure all inputs are completed for each feature and a strategy is
              selected for every feature in the left-hand navigation.
            </div>
            <div className="w-full mb-4">
              <SummaryBox
                label={`Feature Summary:`}
                selectedItem={selectedItem}
                title={selectedTitle}
              />
            </div>
            <div className="mb-4 border border-gray-eac rounded-lg">
              <div className="w-full flex flex-row items-center justify-between p-2 border-b border-gray-eac bg-gray-f9f">
                <span className="font-semibold text-black">
                  Machining Strategies
                </span>
                <button
                  className="bg-surface-secondary button-shadow flex flex-row items-center text-surface-default space-x-2 text-xs font-semibold px-3 py-1.5 rounded"
                  onClick={() => {
                    setShowCreateStrategyModal(true);
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33333 13.6667V8.33333M8.33333 8.33333V3M8.33333 8.33333H13.6667M8.33333 8.33333H3"
                      stroke="#1570EF"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span>Add strategy</span>
                </button>
              </div>
              {!loading && (
                <div
                  className="flex flex-row overflow-x-auto p-2"
                  id="strategies-container"
                >
                  {data?.strategies?.[0]?.machining_strategy?.length ? (
                    data?.strategies?.[0]?.machining_strategy?.map(
                      (item: any, idx: number) => (
                        <MachiningStrategySummaryBox
                          strategy={item}
                          checked={selectedSummary?.index === idx}
                          key={idx}
                          type={
                            item?.custom
                              ? "custom"
                              : item?.recommended && "recommend"
                          }
                          selectedItem={selectedItem}
                          setSelectedSummary={setSelectedSummary}
                          index={idx}
                          setInValid={confirmValidData}
                        />
                      )
                    )
                  ) : (
                    <div
                      className="w-full flex flex-col justify-center items-center
                   py-20 space-y-2"
                    >
                      <img src={NoFoundImg} alt="not-found" />
                      <p className="text-sm font-semibold text-gray-344">
                        Oops! No machining strategy is available.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Hiding strategy rationale */}
            {/* <div className="p-2 bg-gray-f9f rounded-lg border border-gray-eac">
              <p className="text-black font-semibold mb-[10px]">Rationale</p>
              <div>
                {selectedSummary?.rationale &&
                  selectedSummary?.rationale?.map(
                    (item: string, idx: number) => {
                      return (
                        <div className="flex flex-row items-start" key={idx}>
                          <img
                            src={DoubleCheckIcon}
                            alt="double check"
                            className="mr-[6px]"
                          />
                          <p className="text-gray-737 text-sm font-medium">
                            {item}
                          </p>
                        </div>
                      );
                    }
                  )}
              </div>
            </div> */}
          </div>

          <div className="w-full absolute bottom-[4px] left-0 right-0 bg-white flex justify-end items-center py-2 border-t border-gray-e7e pl-4">
            <div className="flex flex-row font-semibold gap-4">
              <Button
                variant="gray"
                onClick={() => handleRedirect("feature-summary")}
              >
                Back
              </Button>
              <div
                className={clsx({
                  "cursor-not-allowed": !selectedFeaturesSet.size,
                })}
                id="machine-btn"
                onMouseOver={() =>
                  !selectedFeaturesSet.size && showTooltip("machine-btn")
                }
                onMouseLeave={() => hideTooltip()}
                data-tooltip-content="You must select at least one feature to continue"
              >
                <Button
                  className={clsx({
                    "pointer-events-none": !selectedFeaturesSet.size,
                  })}
                  onClick={() => handleContinue()}
                  disabled={!selectedFeaturesSet.size}
                >
                  Next : Manufacturing Process
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateStrategyModal
        isOpen={showCreateStrategyModal}
        setIsOpen={setShowCreateStrategyModal}
        data={selectedItem}
        onAdd={addCustomStrategy}
      />
      <UnvisitedFeaturesModal
        isOpen={showUnvisitedFeaturesModal}
        setIsOpen={setShowUnvisitedFeaturesModal}
        featureList={unvisitedFeatures}
        screen="strategy"
        handleContinue={() => {
          dispatch({
            type: SET_VISIBLE_IDS,
            payload: {
              pageMapping: "0",
              ids: Array.from(selectedFeaturesSet),
            },
          });
          handleRedirect("manufacturing-process");
        }}
      />
    </div>
  );
};

export default MachineStrategy;
