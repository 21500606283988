import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useRef, useState, useEffect } from "react";

interface IDisplayCamSpecificFeatureName {
  namesArray: string[];
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#D1E9FF",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#D1E9FF",
    color: "black",
    maxWidth: "200px",
    padding: "8px",
    fontSize: theme.typography.pxToRem(12),
  },
}));

const DisplayCamSpecificFeatureName: React.FC<
  IDisplayCamSpecificFeatureName
> = ({ namesArray }) => {
  const textRef = useRef<any>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    const checkTruncation = () => {
      if (element) {
        setIsTruncated(element.offsetWidth < element.scrollWidth);
      }
    };
    const resizeObserver = new ResizeObserver(checkTruncation);
    if (element) {
      resizeObserver.observe(element);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [namesArray]);

  let fullName = namesArray?.join(", ") || "";
  if (!fullName) {
    return <></>;
  }

  return (
    <HtmlTooltip
      open={showToolTip}
      onOpen={() => {
        if (isTruncated) setShowToolTip(true);
      }}
      onClose={() => {
        setShowToolTip(false);
      }}
      title={<div className="text-wrap whitespace-normal">{fullName}</div>}
      arrow
    >
      <div ref={textRef} className="truncate">
        &nbsp;({fullName})
      </div>
    </HtmlTooltip>
  );
};

export default DisplayCamSpecificFeatureName;
