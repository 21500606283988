import axios from "axios";
import { BASE_URL, loginInfo } from "config";
import { API } from "aws-amplify";
import { backupMachineListMapping, getBackupMachineList } from "./backupData";
import { v4 as uuid } from "uuid";

const API_NAME = process.env.REACT_APP_API_NAME || "";

export const getMachiningStrategy = async (data: any) => {
  const res = await API.post(API_NAME, `/strategy`, { body: data });
  return res;
};

export const getUserDataFromDbAPI = async (
  organizationId: string,
  userRecordId: string
) => {
  const res = await API.get(API_NAME, `user/{organizationId}/{userRecordId}`, {});
  return res;
};

export const getMachineListAPI = async (organizationId: string) => {
  const res = await API.post(API_NAME, `/list-machines/${organizationId}`, {});
  return res;
};

export const getCuttingToolLibraryAPI = async (organizationId: string) => {
  const res = await API.post(
    API_NAME,
    `/list-tool-libraries/${organizationId}`,
    { body: {} }
  );
  return res;
};

export const getCuttingToolAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/tool`, { body: data });
  return res;
};

export const getParametersAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/parameters`, { body: data });
  return res;
  // const res = await axios.post(`${BASE_URL}/parameters`, data, {
  //   headers: getHeader(),
  // });
  // return res.data;
};

export const getStockToRemoveValuesAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/stock_calc`, { body: data });
  return res;
};

// export const getGraphDataAPI = async (data: any) => {
//   const res = await API.post(API_NAME, `/non_optimal_points`, { body: data });
//   return res;
// };

export const postCreateUserSelectionAPI = async (data: any) => {
  const res = await API.post(API_NAME, `/create-user-selections`, {
    body: data,
  });
  return res;
};

// const res = await API.put(
//   API_NAME,
//   `/nc-project-update/${organizationId}/${ncProjectId}`,
//   { body: data }
// );
// return res;

export const putUserSelectionAPI = async (data: any) => {
  const { selectionId, organizationId }: any = { ...data };
  // delete data.organizationId;
  delete data.selectionId;

  const res = await API.put(
    API_NAME,
    `/update-user-selection/${organizationId}/${selectionId}`,
    {
      body: data,
    }
  );
  return res;
};

export const getUserSelectionAPI = async (payload: any) => {
  const res = await API.get(
    API_NAME,
    `/get-user-selection/${payload?.organizationId}/${payload?.selectionId}`,
    {}
  );
  return res;
};

export const postProjectAPI = async (data: any) => {
  const res = await API.post(API_NAME, "/nc-project-create", { body: data });
  return res;
};

export const getProjectsAPI = async ({ organizationId, userRecordId }: any) => {
  const res = await API.get(
    API_NAME,
    `/nc-projects-list/${organizationId}/${userRecordId}`,
    {}
  );
  return res;
};

export const getProjectAPI = async ({ userRecordId, ncProjectId }: any) => {
  const res = await API.get(
    API_NAME,
    `/nc-project-get/${userRecordId}/${ncProjectId}`,
    {}
  );
  return res;
};

export const putProjectAPI = async (data: any) => {
  const { ncProjectId, organizationId }: any = { ...data };
  // delete data.organizationId;
  delete data.ncProjectId;

  const res = await API.put(
    API_NAME,
    `/nc-project-update/${organizationId}/${ncProjectId}`,
    { body: data }
  );
  return res;
};

export const deleteProjectAPI = async (data: any) => {
  const res = await API.put(API_NAME, `/nc-project-delete`, { body: data });
  return res;
};

export const getUserAPI = async ({ userRecordId, organizationId }: any) => {
  const res = await API.get(
    API_NAME,
    `/user/${organizationId}/${userRecordId}`,
    {}
  );
  return res;
};

export const updateUserAPI = async (data: any) => {
  const { userRecordId, organizationId }: any = { ...data };
  delete data.userRecordId;
  delete data.organizationId;
  const res = await API.put(
    API_NAME,
    `/update-user/${organizationId}/${userRecordId}`,
    { body: data }
  );
  return res;
};

export const sendUserSelectionsToICSApi = async (data: any) => {
  const res = await API.post(API_NAME, "/push-user-selection-to-ics", {
    body: data,
  });
  return res;
};

export const generateTokenAPI = async () => {
  const res = await API.post(API_NAME, "/generate-token", { body: {} });
  return res;
};

export const getOrganizationAPI = async ({
  userRecordId,
  organizationId,
}: any) => {
  const res = await API.get(
    API_NAME,
    `/corporate-record/${userRecordId}/${organizationId}`,
    {}
  );
  return res;
};

export const getICSMachineListMapping = async (
  icsDomain: string,
  token: string
) => {
  try {
    const resp = await axios.get(
      `https://api-${icsDomain}.lambdafunction.ai/api/machines/get-machine-data/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  } catch (err: any) {
    if (err?.response?.data?.detail === "Invalid token") {
      return err?.response?.data;
    }
    return backupMachineListMapping;
  }
};

export const getICSMachineList = async (
  icsDomain: string,
  machineTypeId: number,
  machineSubtypeId: number,
  numberOfAxis: number,
  token: string
) => {
  try {
    const resp = await axios.get(
      `https://api-${icsDomain}.lambdafunction.ai/api/machines/list-machines/`,
      {
        params: {
          page: 1,
          page_size: 1000,
          status: 1,
          machine_type: machineTypeId,
          machine_sub_type: machineSubtypeId,
          number_of_axes: numberOfAxis,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data?.results;
  } catch (err: any) {
    return getBackupMachineList(machineTypeId, machineSubtypeId, numberOfAxis);
  }
};

export const getCustomStrategyData = async ({
  featureName,
  camSoftware,
}: {
  featureName: string;
  camSoftware: string;
}) => {
  const res = await API.get(API_NAME, "/strategy/custom", {
    queryStringParameters: {
      cam_software: camSoftware,
      feature: featureName,
    },
  });
  return res;
};

export const saveCustomTool = async ({
  organizationId,
  userRecordId,
  toolData,
}: {
  organizationId: string;
  userRecordId: string;
  toolData: any;
}) => {
  const res = await API.post(API_NAME, "/create-tool-libraries", {
    body: {
      organizationId,
      userRecordId,
      libraryId: "user_added_custom_tool_lib",
      toolId: uuid(),
      ...toolData,
    },
  });

  return res;
};
