import clsx from "clsx";
import { SET_VISITED_STEPS } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import StepWithSelectedTool from "assets/images/icons/step-with-selected-tool.svg";
import StepIcon from "assets/images/icons/StepIcon";

interface IStepItem {
  index: number;
  label: string;
  key: string;
  isNull?: boolean;
  operations?: any;
}
interface IStep {
  data: IStepItem[];
  current: { index: number; subIndex: number; featureId: string };
  setSelectedStep?: any;
}

interface ISubDot {
  className?: string;
  styles?: any;
  visited: boolean;
  toolSelected: boolean;
  setSelectedStep?: any;
  data?: any;
  subIndex?: number;
}

const SubDot: React.FC<ISubDot> = ({
  className,
  visited,
  toolSelected,
  data,
  subIndex,
  setSelectedStep,
}) => {
  return (
    <div
      className={clsx("w-4 h-4 cursor-pointer", className)}
      onClick={() => {
        setSelectedStep?.({
          ...data,
          subIndex,
        });
      }}
    >
      {visited && toolSelected ? (
        <img src={StepWithSelectedTool} alt="step" />
      ) : (
        <StepIcon visited={visited} />
      )}
    </div>
  );
};

// index - passes
// subIndex - operations
const Step: React.FC<IStep> = ({ data, current, setSelectedStep }) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { stepsVisitedStatus } = state;
  let count: number = 0;
  return (
    <div className={clsx("flex flex-row")}>
      {data?.map((item, idx) => {
        return (
          <>
            {!!item?.operations?.length &&
              item?.operations?.map((operation: any, idx1: number) => {
                count++;
                const stepNumber = count;
                return (
                  <div
                    className="flex flex-row items-center gap-0"
                    key={`${idx}-${idx1}`}
                  >
                    {count > 1 && (
                      <div className="w-full h-[2px] w-[70px] rounded mx-1.5 !bg-surface-default"></div>
                    )}
                    <div
                      className="flex flex-row items-center cursor-pointer"
                      onClick={() => {
                        if (
                          current?.index === item.index &&
                          current?.subIndex === idx1
                        )
                          return;
                        // Marking step visited and updating previous step visited
                        const featureStatus =
                          stepsVisitedStatus[current.featureId];
                        featureStatus.visited.add(stepNumber);

                        Object.assign(featureStatus, {
                          lastVisitedPassIndex: idx,
                          lastVisitedOperationIndex: idx1,
                        });

                        dispatch({
                          type: SET_VISITED_STEPS,
                          payload: stepsVisitedStatus,
                        });
                        setSelectedStep({ ...item, subIndex: idx1 });
                      }}
                    >
                      <div className="flex flex-col justify-center items-center relative">
                        <SubDot
                          key={idx1}
                          visited={stepsVisitedStatus[
                            current.featureId
                          ].visited.has(stepNumber)}
                          data={item}
                          subIndex={idx1}
                          setSelectedStep={
                            current?.index === item.index &&
                            current?.subIndex === idx1
                              ? undefined
                              : setSelectedStep
                          }
                          toolSelected={operation?.selectedToolId}
                        />
                        <span
                          className={clsx(
                            "absolute text-xs whitespace-nowrap font-medium text-gray-344 top-[20px]",
                            {
                              "!text-surface-default":
                                current?.index === item.index &&
                                current?.subIndex === idx1,
                            }
                          )}
                        >
                          {`Step ${count}`}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        );
        // return (
        //   <div
        //     className={clsx("flex flex-row items-center", {
        //       "w-full": idx < data?.length,
        //     })}
        //     key={idx}
        //   >
        //     { idx >= 1 && <div className="mx-1.5 flex flex-row w-full">
        //       {/* {item?.operations?.map((operation: any, idx1: number) => (
        //         <>
        //           <div
        //             className={clsx("w-full h-1 rounded mt-1.5", {
        //               "bg-gray-d9d": idx < data?.length,
        //               "!bg-surface-default":
        //                 item?.index <= current?.index &&
        //                 idx1 <= current?.subIndex,
        //             })}
        //           ></div>
        //           <SubDot
        //             key={idx1}
        //             isActive={
        //               item?.index <= current?.index && idx1 <= current?.subIndex
        //             }
        //             data={item}
        //             subIndex={idx1}
        //             setSelectedStep={setSelectedStep}
        //           />
        //         </>
        //       ))} */}
        //       <div
        //         className={clsx("w-full h-1 w-[70px] rounded mt-0.5", {
        //           "bg-gray-d9d": idx < data?.length,
        //           "!bg-surface-default": item?.index <= current?.index,
        //         })}
        //       ></div>
        //     </div>}

        //     <div
        //       className="flex flex-row items-center cursor-pointer"
        //       onClick={() => setSelectedStep({ ...item, subIndex: 0 })}
        //     >
        //       <div className="flex flex-col justify-center items-center relative">
        //         {/* <div
        //           className={clsx(
        //             "w-6 h-6 flex justify-center items-center rounded-full font-semibold",
        //             {
        //               "text-white bg-surface-default":
        //                 item?.index <= current?.index,
        //               "text-gray-807 bg-gray-d9d": item?.index > current?.index,
        //             }
        //           )}
        //         /> */}
        //         <SubDot
        //           key={idx}
        //           isActive={item?.index <= current?.index}
        //           data={item}
        //           subIndex={idx}
        //           setSelectedStep={setSelectedStep}
        //         />
        //         <span
        //           className={clsx(
        //             "absolute text-xs whitespace-nowrap font-medium text-gray-344 top-[28px]"
        //           )}
        //         >
        //           {/* {item?.label} */} {`Step ${idx + 1}`}
        //         </span>
        //       </div>
        //     </div>

        //   </div>
        // );
      })}
    </div>
  );
};

export default Step;
