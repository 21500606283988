import { Dispatch, SetStateAction } from "react";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
export interface IToolbox {
  className?: string;
  tool?: any;
  index: number;
  selectedTool?: any;
  setSelectedTool?: any;
  confirmInValidData?: Function;
  selectedStep: any;
  boxData: any;
  setBoxData: Dispatch<SetStateAction<any>>;
}

// 1. Tool specification
// 2. Tool material
// 3. Diameter (mm)
// 4. Flute Length (mm)
// 5. Total Length (mm)

const fetchCommonLabels = (measurement_unit: string) => {
  return [
    { label: "Tool ID", value: "Tool ID", unit: "" },
    { label: "Tool material", value: "Body material code", unit: "" },
    { label: "Total Length", value: "Body length", unit: measurement_unit },
    {
      label: "Cutting Diameter",
      value: "Cutting diameter",
      unit: measurement_unit,
    },
    { label: "Flute Length", value: "Flute length", unit: measurement_unit },
    {
      label: "Shaft Diameter",
      value: "Shaft diameter",
      unit: measurement_unit,
    },
    { label: "Flute Count", value: "Flute count", unit: "" },
  ];
};

const fetchToolTipContent = (toolType: string, measurement_unit: string) => {
  let labelArray = [...fetchCommonLabels(measurement_unit)];
  switch (toolType) {
    case "face mill":
    case "slot mill":
    case "t-slot mill":
    case "bull nose end mill":
    case "reamer":
      labelArray.push({
        label: "Corner Radius",
        value: "Corner radius",
        unit: measurement_unit,
      });
      break;
    case "boring bar":
      labelArray.push(
        {
          label: "Corner Radius",
          value: "Corner radius",
          unit: measurement_unit,
        },
        { label: "Description", value: "Description", unit: "" }
      );
      break;
    case "chamfer mill":
    case "taper mill":
    case "tapered ball":
    case "tapered bull nose":
      labelArray.push({ label: "Taper Angle", value: "Taper angle", unit: "" });
      break;
    case "center drill":
    case "spot drill":
    case "drill":
      labelArray.push({ label: "Point angle", value: "Point angle", unit: "" });
      break;
    case "tap":
    case "tap right hand":
    case "thread former":
    case "thread mill":
      labelArray.push({
        label: "Thread Pitch",
        value: "Thread pitch",
        unit: measurement_unit,
      });
      break;
  }
  return labelArray;
};

export const TooltipContent = ({
  tool,
  measurement_unit,
}: {
  tool: any;
  measurement_unit: string;
}) => {
  const TOOL_TYPE = tool?.["Tool type"];
  const LABEL_ARRAY = fetchToolTipContent(TOOL_TYPE, measurement_unit);
  const featureType: string = tool?.additional_info?.feature_type ?? "";

  return (
    <div className="text-[10px] text-gray-600 break-words whitespace-normal">
      {featureType === "hole" && (
        <div className="rounded flex items-center gap-2 w-full py-1">
          <img src={FeatureInfoIcon} alt="" width="24" />
          Select tools and parameters for each step of a feature in the
          left-hand navigation and top-right bar.
        </div>
      )}
      {LABEL_ARRAY.map(({ label, value, unit }, idx) =>
        !unit ? (
          <p key={`${label}`}>
            {`${label}: `}
            <b>{`${tool?.[value] ?? "N/A"}`}</b>
          </p>
        ) : (
          <p key={`${label}`}>
            {`${label} (${unit}): `}
            <b>{`${tool?.[value] ?? "N/A"}`}</b>
          </p>
        )
      )}
    </div>
  );
};

export const InvalidTooltipContent = ({ data }: any) => {
  return (
    <div
      className="max-w-[200px] h-auto"
      style={{ overflowWrap: "break-word" }}
    >
      {data}
    </div>
  );
};
