import {
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import StepWithSelectedTool from "assets/images/icons/step-with-selected-tool.svg";
import StepIcon from "assets/images/icons/StepIcon";
import Button from "components/Button";
import { useContext, useRef, useState } from "react";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import { updateUserAPI } from "services";
import { updateUserFlag } from "utils";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#98A2B3",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "black",
    width: "280px",
    border: "2px solid #98A2B3",
    margin: "12px",
    padding: "16px",
    borderRadius: "8px !important",
  },
}));

const StepIconInfoModal: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
}> = ({ open, setOpen, children }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const selectRef = useRef<any>();
  const { userInfo } = state;
  const [updateUser] = useApiCall(updateUserAPI);
  const [dontShowModal, setDontShowModal] = useState(false);

  const handleContinue = () => {
    setOpen(false);
    if (dontShowModal) {
      updateUserFlag("hideStepsInfoModal", userInfo, updateUser, dispatch);
    }
  };

  return (
    <HtmlTooltip
      title={
        <div ref={selectRef}>
          <div className="flex flex-col gap-2 text-[14px] text-gray-344">
            <div className="flex gap-2">
              <img src={StepWithSelectedTool} alt="step" /> Tool selected
            </div>
            <div className="flex gap-2">
              <StepIcon visited={true} /> Tool not selected
            </div>
            <div className="flex gap-2">
              <StepIcon visited={false} /> Unvisited step
            </div>
          </div>
          <div className="flex flex-row w-full justify-between pt-4 items-center">
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <MuiCheckbox
                      checked={dontShowModal}
                      onChange={() => {
                        setDontShowModal(!dontShowModal);
                      }}
                    />
                  }
                  label="Don't show again"
                  slotProps={{
                    typography: {
                      className: "text-[12px] text-gray-700 font-inter",
                    },
                  }}
                />
              </FormGroup>
            </div>
            <div>
              <Button
                size="sm"
                className="px-[8px] py-2 font-semibold rounded-[4px] w-[80px] text-center bg-surface-default text-white hover:opacity-80 active:opacity-50"
                onClick={handleContinue}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      }
      open={open}
      arrow={true}
    >
      {children}
    </HtmlTooltip>
  );
};

export default StepIconInfoModal;
