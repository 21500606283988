import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/material";
import { useState } from "react";
interface HtmlTooltipProps extends TooltipProps {
  maxWidth?: string; // Adding maxWidth as a custom prop
}

const HtmlTooltip = styled(
  ({ className, maxWidth, ...props }: HtmlTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#D1E9FF",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#D1E9FF",
    color: "black",
    maxWidth: maxWidth,
    padding: "8px",
    fontSize: theme.typography.pxToRem(12),
  },
}));

interface ITooltipV2 {
  title: React.ReactNode;
  openOnHover?: boolean;
  children: React.ReactNode;
  slotProps?: any;
  maxWidth?: string;
}
const TooltipV2: React.FC<ITooltipV2> = ({
  title,
  openOnHover = true,
  children,
  slotProps = { popper: { disablePortal: true } },
  maxWidth = "200px",
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className="inline-flex">
        <HtmlTooltip
          slotProps={slotProps}
          onClose={handleTooltipClose}
          placement="top"
          disableFocusListener
          disableTouchListener
          open={openOnHover ? undefined : open}
          disableHoverListener={!openOnHover}
          title={title}
          arrow
          maxWidth={maxWidth}
        >
          <div className="inline-block">
            {openOnHover ? (
              children
            ) : (
              <button
                onClick={() => {
                  setOpen((open) => !open);
                }}
              >
                {children}
              </button>
            )}
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipV2;
