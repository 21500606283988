import clsx from "clsx";
import { featureInputsWithNoUnits } from "constant";
import { capitalize } from "utils";
import { Tooltip } from "react-tooltip";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TooltipIslandInfo from "components/TooltipIslandInfo";
import DisplayCamSpecificFeatureName from "components/DisplayCamSpecificFeatureName";
import CamFeatureHightlightButton from "components/CamFeatureHighlightButton";
import { Checkbox } from "@mui/material";

const FeatureSummaryItem = ({ data, handleSubChecked, index, unit }: any) => {
  return (
    <div>
      {data?.map((item: any, idx: number) => (
        <div key={idx} className={clsx({ "mb-4": idx < data?.length - 1 })}>
          <div className="border-b border-gray-e3e pb-2 mb-2">
            <div className="flex gap-[6px]">
              <Checkbox
                checked={item?.checked}
                onChange={(e: any) => {
                  handleSubChecked(index, idx, e.target.checked);
                }}
                className="!p-0"
                indeterminate={false}
              />
              <div className="inline-flex whitespace-nowrap overflow-hidden w-full font-semibold">
                {item?.tag_id ? (
                  <CamFeatureHightlightButton
                    tagId={item.tag_id}
                    label={item.title}
                    tooltipContainer="features-container"
                  />
                ) : (
                  item?.title
                )}
                <DisplayCamSpecificFeatureName
                  namesArray={item.camSpecificNames}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4">
            {item?.data?.map((item1: any, idx1: number) => {
              const measurement_unit = !featureInputsWithNoUnits.has(
                item1?.inputKey
              )
                ? " " + unit
                : "";
              return item1?.key !== "Islands" ? (
                <div key={idx1} className="col-span-1 flex flex-row mb-1">
                  <span className="text-sm font-semibold text-gray-b9b mr-1">
                    {item1?.key}:
                  </span>
                  <span className="text-sm font-medium text-black">
                    {capitalize(item1?.value) + measurement_unit}
                  </span>
                </div>
              ) : (
                <>
                  <div key={idx1} className="col-span-1 flex flex-row mb-1">
                    <span
                      className="text-sm font-semibold text-gray-b9b mr-1"
                      id={`feature-${item?.feature_id}-islandData`}
                    >
                      Islands :
                    </span>
                    <span className="text-sm font-medium text-black">
                      {item1?.value?.islands?.length ?? 0}
                    </span>
                  </div>
                  {item1?.value?.islands?.length > 1 && (
                    <div
                      key={idx1 + 1}
                      className="col-span-1 flex flex-row mb-1"
                    >
                      <span className="text-sm font-semibold text-gray-b9b mr-1">
                        Min Distance between Islands :
                      </span>
                      <span className="text-sm font-medium text-black">
                        {item1?.value?.min_distance_between_islands
                          ? item1?.value?.min_distance_between_islands +
                            measurement_unit
                          : ""}
                      </span>
                    </div>
                  )}
                  <div className="col-span-4 text-xs flex flex-wrap w-full gap-4">
                    {item1?.value?.islands?.length > 0 && (
                      // The reason we are not using the already present tooltip
                      // utility is because that does not support are requirements
                      <Tooltip
                        anchorSelect={`#feature-${item?.feature_id}-islandData`}
                        style={{
                          backgroundColor: "#D1E9FF",
                          color: "#344054",
                          boxShadow:
                            "1px 0px 4px rgba(0, 0, 0, .15), 0px 1px 4px rgba(0, 0, 0, .25)",
                          minWidth: "200px",
                          maxWidth: "230px",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                        place={"bottom"}
                        clickable
                      >
                        {item1?.value?.islands?.length > 1 ? (
                          <div>
                            <Carousel
                              showArrows={false}
                              showThumbs={false}
                              showIndicators={true}
                              showStatus={false}
                              // infiniteLoop={true}
                              // autoPlay={true}
                              // interval={2000}
                              transitionTime={0}
                            >
                              {item1?.value?.islands?.map?.(
                                (islandData: any, idx2: number) => (
                                  <TooltipIslandInfo
                                    key={`${idx}_${idx2}`}
                                    island={islandData}
                                    index={idx2}
                                    unit={measurement_unit}
                                  ></TooltipIslandInfo>
                                )
                              )}
                            </Carousel>
                          </div>
                        ) : (
                          <TooltipIslandInfo
                            island={item1?.value?.islands[0]}
                            index={0}
                            unit={measurement_unit}
                          ></TooltipIslandInfo>
                        )}
                      </Tooltip>
                    )}
                  </div>
                </>
              );
            })}
          </div>
          {!!item?.deepData?.length &&
            item?.deepData?.map((deepData: any, idx2: number) => (
              <div className="grid grid-cols-4 border-t py-2 mt-2" key={idx2}>
                {deepData?.map((item2: any, idx3: number) => (
                  <div key={idx3} className="col-span-1 flex flex-row mb-1">
                    <span className="text-sm font-semibold text-gray-b9b mr-1">
                      {item2?.key}:
                    </span>
                    <span className="text-sm font-medium text-black">
                      {item2?.value}
                    </span>
                  </div>
                ))}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default FeatureSummaryItem;
