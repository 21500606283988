import { labelToKey } from "./commonUtils";

export const extractToolPathDataFromMachiningSetups = (
  setupIdx: number,
  machiningSetups: any,
  featureMapping: any,
  visiblefeatureIdsSet: any,
  projectMeasurementUnit: string,
  numberOfAxes: number,
  mappingForMachiningSetups: any
) => {
  const finalObject: any = {
    measurement_unit: projectMeasurementUnit || "mm",
    tools: [],
    parameters_units: {},
    tool_paths: [],
    machine_details: {
      number_of_axes: numberOfAxes,
    },
  };
  const toolIdSet = new Set();

  const featuresArray = JSON.parse(
    JSON.stringify(machiningSetups?.[setupIdx]?.features)
  );

  for (const feature of featuresArray) {
    const selectedStrategy =
      feature.featureInformation?.strategies?.[0]?.machining_strategy?.find?.(
        (item: any) => item?.isSelected
      );
    if (!selectedStrategy || !visiblefeatureIdsSet.has(feature.feature_id))
      continue;
    selectedStrategy?.passes?.forEach((pass: any) => {
      pass.operations?.forEach((operation: any) => {
        if (!operation.selectedToolId) return;
        const [selectedToolData, selectedToolParameters] = extractSelectedTool(
          operation.parameters,
          operation.selectedToolId,
          operation.tools
        );
        if (!toolIdSet.has(operation.selectedToolId)) {
          delete selectedToolData.isValid;
          delete selectedToolData.feedback;
          finalObject.tools.push(selectedToolData);
          toolIdSet.add(operation.selectedToolId);
        }
        const reqParams = extractRequiredParameters(
          selectedToolParameters,
          finalObject.parameters_units
        );
        const toolPathData = {
          feature_id: feature.feature_id,
          ...(feature.featureInformation.tag_id && {
            tag_id: feature.featureInformation.tag_id,
          }),
          "Tool ID": operation.selectedToolId,
          feature_type: feature.featureInformation.feature_data.feature_type,
          feature_name: feature.featureInformation.feature_data.feature_name,
          operation: operation.operation,
          cutting_pass: pass.pass,
          feature_number: featureMapping?.[feature.feature_id]?.serialNumber,
          phase_number: operation.pass_number ?? -1,
          assigned_setup:
            mappingForMachiningSetups?.[operation.assignedSetupId]?.name,
          tool_path: operation.tool_path,
          tool_path_type: operation.tool_path_type,
          tool_type: operation.tool_type,
          tool_path_style:
            operation.tool_paths?.[operation.selectedToolPathInd ?? 0]
              ?.tool_path_style ?? "N/A",
          tool_path_syntax:
            operation.tool_paths?.[operation.selectedToolPathInd ?? 0]
              ?.tool_path_syntax ?? "N/A",
          parameters: reqParams,
          [`tool_path_parameters_${labelToKey(operation.tool_path)}`]:
            selectedToolParameters?.tool_path_parameters ?? {},
        };
        finalObject.tool_paths.push(toolPathData);
      });
    });
  }

  return finalObject;
};

const extractSelectedTool = (
  parametersObjArray: any[],
  selectedToolId: string,
  toolsObjarray: any[]
) => {
  return JSON.parse(
    JSON.stringify([
      toolsObjarray?.find?.((toolsObj) => {
        return toolsObj["Tool ID"] === selectedToolId;
      }) || {},
      parametersObjArray?.find?.((parametersObj) => {
        return parametersObj["Tool ID"] === selectedToolId;
      }) || {},
    ])
  );
};

const extractRequiredParameters = (
  parametersObj: any,
  parametersUnitsObj: any
) => {
  const categoryParametersSet = new Set(["none"]); // This is not being used currenlty.
  const normalParametersSet = new Set([
    "Spindle speed",
    "Material removal rate",
    "Cutting velocity",
    "Power",
    "Feed rate",
    "Stepover [Ae]",
    "Stepdown [Ap]",
    "Torque",
    "Tool life",
  ]);

  const finalParametersObject: any = {};

  for (const parameterName in parametersObj) {
    if (categoryParametersSet.has(parameterName)) {
      for (const subCatName in parametersObj[parameterName]) {
        for (const subCatParamKey in parametersObj[parameterName][subCatName]) {
          if (!(subCatParamKey in parametersUnitsObj)) {
            parametersUnitsObj[subCatParamKey] =
              parametersObj[parameterName][subCatName][subCatParamKey].unit ||
              "";
          }
          finalParametersObject[subCatParamKey] =
            parametersObj[parameterName][subCatName][subCatParamKey].value ??
            null;
        }
      }
    } else if (normalParametersSet.has(parameterName)) {
      if (!(parameterName in parametersUnitsObj)) {
        parametersUnitsObj[parameterName] =
          parametersObj[parameterName].unit || "";
      }
      finalParametersObject[parameterName] =
        parametersObj[parameterName].value ?? null;
    }
  }
  return finalParametersObject;
};

export const previewToolPath = (
  projectMeasurementUnit: string,
  numberOfAxes: number,
  operation: any,
  pass: string,
  feature: any,
  featureMapping: any,
  mappingForMachiningSetups: any,
  toolId: string
) => {
  const finalObject: any = {
    measurement_unit: projectMeasurementUnit || "mm",
    tools: [],
    parameters_units: {},
    tool_paths: [],
    machine_details: {
      number_of_axes: numberOfAxes,
    },
  };

  if (!operation.selectedToolId) return;
  const [selectedToolData, selectedToolParameters] = extractSelectedTool(
    operation.parameters,
    toolId,
    operation.tools
  );
  finalObject.tools.push(selectedToolData);
  const reqParams = extractRequiredParameters(
    selectedToolParameters,
    finalObject.parameters_units
  );

  const toolPathData = {
    feature_id: feature.feature_id,
    ...(feature.featureInformation.tag_id && {
      tag_id: feature.featureInformation.tag_id,
    }),
    "Tool ID": toolId,
    feature_type: feature.featureInformation.feature_data.feature_type,
    feature_name: feature.featureInformation.feature_data.feature_name,
    operation: operation.operation,
    cutting_pass: pass,
    feature_number: featureMapping?.[feature.feature_id]?.serialNumber,
    phase_number: operation.pass_number ?? -1,
    assigned_setup:
      mappingForMachiningSetups?.[operation.assignedSetupId]?.name,
    tool_path: operation.tool_path,
    tool_path_type: operation.tool_path_type,
    tool_type: operation.tool_type,
    tool_path_style:
      operation.tool_paths?.[operation.selectedToolPathInd ?? 0]
        ?.tool_path_style ?? "N/A",
    parameters: reqParams,
    [`tool_path_parameters_${labelToKey(operation.tool_path)}`]:
      selectedToolParameters?.tool_path_parameters ?? {},
  };
  finalObject.tool_paths.push(toolPathData);

  return finalObject;
};
