import Button from "components/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Dispatch, SetStateAction } from "react";
import { FiCheckCircle } from "react-icons/fi";

interface IToolpathGenerationSuccessfulModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  maxWidth: "450px",
  width: "100%",
  borderRadius: "16px",
  outline: "none",
};

const ToolpathGenerationSuccessfulModal: React.FC<
  IToolpathGenerationSuccessfulModal
> = ({ isOpen, setIsOpen }) => {
  return (
    <div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={modalStyle}>
          <div className="text-green-00d flex flex-col items-center justify-between pt-[16px] pb-[16px] gap-2">
            <FiCheckCircle className="text-green-00d font-semibold ml-2 h-12 w-12" />
          </div>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "60vh",
            }}
          >
            <div className="mt-0">
              <div className="text-center">
                <p className="font-semibold text-base text-gray-202">
                  Toolpath generation is successful.
                </p>
              </div>
              <div className="text-[14px] text-center text-gray-700">
                Minimize the application and navigate to CAM to continue.
              </div>
            </div>
          </Box>
          <div className="flex justify-center w-full p-[16px] flex-row space-x-4">
            <Button
              size="md"
              className="px-[16px] py-[8px] text-[14px] font-semibold font-inter normal-case rounded-[4px] w-[150px] text-center  bg-surface-default text-white hover:opacity-80 active:opacity-50"
              onClick={() => setIsOpen(false)}
            >
              Ok
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ToolpathGenerationSuccessfulModal;
