import { isSpecialCharacterRegExp } from "constant";
import { v4 as uuid } from "uuid";
declare const window: any;

export const fetchSetupIdx = (machiningSetups: any, setupNumber: any) => {
  return machiningSetups?.findIndex(
    (setup: any) => setup?.machiningSetupNumber === setupNumber
  );
};

export const compareFuncForObjectsWithPassNumber = (obj1: any, obj2: any) => {
  if (obj1.pass_number < obj2.pass_number) return -1;
  else if (obj1.pass_number > obj2.pass_number) return 1;
  else return 0;
};

export function createDeepCopy(data: any) {
  return JSON.parse(JSON.stringify(data));
}

export function camNameValidation(camName: string) {
  return ["master cam", "siemens", "fusion 360"].includes(camName);
}

export function resetScroll(id: string, direction: "scrollLeft" | "scrollTop") {
  const element: any = document.getElementById(id);
  if (element) {
    element[direction] = 0;
  }
}

export const projectComparator = (field: string, order: string) => {
  return function (project1: any, project2: any) {
    switch (field) {
      case "projectName":
      case "cadPartId":
      case "material":
      case "machineName": {
        const param1 =
            project1[field]?.replace(isSpecialCharacterRegExp, "") || "",
          param2 = project2[field]?.replace(isSpecialCharacterRegExp, "") || "";
        return (
          (order === "asc" ? 1 : -1) *
          param1.localeCompare(param2, { sensitivity: "base" })
        );
      }
      case "createdAt": {
        const param1 = project1[field]
            ? new Date(project1[field]).getTime()
            : 0,
          param2 = project2[field] ? new Date(project2[field]).getTime() : 0;
        return (order === "asc" ? 1 : -1) * (param1 - param2);
      }
      default:
        return 0;
    }
  };
};

export function labelToKey(input: string): string {
  return input.toLowerCase().replace(/\s+/g, "_");
}

export function roundOff(input: number, precision: number = 10000): number {
  return Math.round(input * precision) / precision;
}

export function notifyCAM(type: string, args: any) {
  const callbackId = "lfReactPluginEvents_" + uuid();
  if (window.chrome?.webview)
    window.chrome.webview.postMessage({ type, args, callbackId });
}

export async function updateUserFlag(
  flag: string,
  userInfo: any,
  updateUser: any,
  dispatch: any
) {
  const payload = {
    userFlags: {
      ...userInfo?.userFlags,
      [flag]: true,
    },
    userRecordId: userInfo?.["sub"],
    organizationId: userInfo?.["custom:organizationId"],
  };
  try {
    await updateUser(payload);
    const userInfoCopy = createDeepCopy(userInfo);
    userInfoCopy.userFlags = {
      ...userInfoCopy.userFlags,
      [flag]: true,
    };
    dispatch({
      type: "SET_USER_INFO",
      payload: userInfoCopy,
    });
  } catch {}
}
