import DefaultLayout from "Layouts/DefaultLayout";
import queryString from "query-string";
import { TAB_LIST } from "constant";
import { useLocation, useNavigate } from "react-router-dom";
import CreateProject from "./CreateProject";
import FeatureRecognition from "./FeatureRecognition";
import MachineStrategy from "./MachineStrategy";
import CuttingTool from "./CuttingTool";
import Summary from "./Summary";
import clsx from "clsx";
import FeatureSummary from "./FeatureSummary";
import DefaultRecommendations from "./DefaultRecommendations";
import MachiningStrategySummary from "./MachiningStrategySummary";
import ManufacturingProcess from "./ManufacturingProcess";
import ManufacturingProcessSummary from "./ManufacturingProcessSummary";
import SelectedIcon from "assets/images/icons/select-icon.svg";
import FilledIcon from "assets/images/icons/filled-icon.svg";
import UnvisitedIcon from "assets/images/icons/unselect-icon.svg";
import TooltipV2 from "components/ToolTipV2";

const Project = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);
  const selectedTab = params?.tab || "project";
  const selectedTabIndex = TAB_LIST?.findIndex(
    (item: any) => item.type === selectedTab
  );
  const handleClick = (idx: number, type: string) => {
    if (idx === 0) {
      navigate("/projects");
    } else if (idx < selectedTabIndex) navigate(`/project?tab=${type}`);
  };

  let View = undefined;
  let noLayout = false;
  if (selectedTab === "project") {
    View = CreateProject;
  } else if (selectedTab === "feature") {
    View = FeatureRecognition;
  } else if (selectedTab === "default-recommendations") {
    View = DefaultRecommendations;
  } else if (selectedTab === "machine") {
    View = MachineStrategy;
  } else if (selectedTab === "tool") {
    View = CuttingTool;
  } else if (selectedTab === "summary") {
    View = Summary;
    noLayout = true;
  } else if (selectedTab === "feature-summary") {
    View = FeatureSummary;
  } else if (selectedTab === "strategy-summary") {
    View = MachiningStrategySummary;
  } else if (selectedTab === "manufacturing-process") {
    View = ManufacturingProcess;
  } else if (selectedTab === "manufacturing-summary") {
    View = ManufacturingProcessSummary;
    noLayout = true;
  }

  return (
    <DefaultLayout noLayout={noLayout}>
      {/* Tab  */}
      {!noLayout && (
        <div className="flex flex-row p-[16px] topbar-container">
          {TAB_LIST?.map((item, idx) => {
            const Icon = item?.icon;
            let iconColor: string,
              textClass: string,
              iconClass: string,
              checkpointIcon: string,
              isCheckpoint = [2, 4].includes(idx);
            switch (true) {
              case idx < selectedTabIndex:
                iconColor = "#1570EF";
                textClass = "text-gray-700";
                iconClass = "border-surface-default bg-white";
                checkpointIcon = FilledIcon;
                break;
              case idx > selectedTabIndex:
                iconColor = "#98A2B3";
                textClass = "text-gray-400";
                iconClass = "bg-white border-gray-200";
                checkpointIcon = UnvisitedIcon;
                break;
              default:
                iconColor = "white";
                textClass = "text-surface-default";
                iconClass = "bg-surface-default border-surface-default";
                checkpointIcon = SelectedIcon;
                break;
            }
            return (
              <div
                className="icon-container flex flex-col justify-start items-center gap-[10px] relative"
                key={idx}
              >
                {idx !== 0 && (
                  <div
                    className={clsx(
                      "z-[-1] fillprogress absolute top-[18px] h-[4px] w-full right-[50%]",
                      {
                        "bg-surface-default": idx <= selectedTabIndex,
                        "bg-gray-400": idx > selectedTabIndex,
                        "!w-[170%]": idx === 1,
                      }
                    )}
                  ></div>
                )}
                <div
                  className={clsx(
                    "w-[40px] h-[40px] rounded-[30px] flex items-center justify-center relative",
                    iconClass,
                    {
                      "border-[2px]": !isCheckpoint,
                      "!bg-transparent": isCheckpoint,
                    }
                  )}
                >
                  <button
                    className="inline-flex"
                    onClick={() => handleClick(idx, item.type)}
                    disabled={idx >= selectedTabIndex}
                  >
                    {isCheckpoint ? (
                      <TooltipV2 title={item.tooltipTitle}>
                        {}
                        <img src={checkpointIcon} alt="checkpoint" />
                      </TooltipV2>
                    ) : (
                      <Icon color={iconColor} />
                    )}
                  </button>
                </div>

                <span
                  className={clsx(
                    "font-semibold text-center text-sm ml-1",
                    textClass
                  )}
                >
                  {item?.label}
                </span>
              </div>
            );
          })}
        </div>
      )}

      <div
        className={clsx("relative ", {
          "mt-0 flex flex-col h-full overflow-hidden": !noLayout,
          "h-full": noLayout,
        })}
      >
        {!!selectedTab && View && <View />}
      </div>
    </DefaultLayout>
  );
};

export default Project;
