import RadioComponent from "components/RadioComponent";
import { SET_MACHINE_SETUP } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useState, useContext, useEffect } from "react";
import SimpleHole from "./SimpleHole";
import ThreadHole from "./ThreadHole";
import { isFeatureInfoModified } from "../utils";

const HoleOptions = [
  { label: "Simple Hole", value: "simple_hole" },
  { label: "Thread Hole", value: "thread_hole" },
];

interface IHoleForm {
  feature_id?: string;
  setAllFieldsTouched?: boolean;
}

const HoleForm: React.FC<IHoleForm> = ({ feature_id, setAllFieldsTouched }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { setupNumber, machiningSetups } = state;
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    const feature_data = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation?.feature_data,
    };
    setSelectedFeature(feature_data);
  }, [setupNumber, feature_id, machiningSetups]);

  const handleHoleOption = (val: string) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_data = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    updated_data = {
      ...updated_data,
      feature_name: val,
      // Setting default value for quantity.
      feature_info: {
        quantity: 1,
      },
    };
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data = updated_data;

    setSelectedFeature(updated_data);

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };
  return (
    <div>
      <RadioComponent
        options={HoleOptions}
        setValue={handleHoleOption}
        value={selectedFeature?.feature_name}
        labelClassName="!mr-4"
        showChangeWarning={isFeatureInfoModified(
          machiningSetups,
          selectedFeature?.feature_id
        )}
      />
      {selectedFeature?.feature_name === "simple_hole" && (
        <SimpleHole
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
          setAllFieldsTouched={setAllFieldsTouched}
        />
      )}
      {selectedFeature?.feature_name === "thread_hole" && (
        <ThreadHole
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
          setAllFieldsTouched={setAllFieldsTouched}
        />
      )}
    </div>
  );
};

export default HoleForm;
