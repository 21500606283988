export interface IFeatureItem {
  item?: any;
  selected?: string;
  onSelect?: any;
}

export interface ICoordinateSystem {
  Xx: number;
  Xy: number;
  Xz: number;
  Yx: number;
  Yy: number;
  Yz: number;
  Zx: number;
  Zy: number;
  Zz: number;
}

export interface IPositionInfo {
  X: number;
  Y: number;
  Z: number;
}

export const isFeatureInfoModified = (
  machiningSetups: any,
  featureId: string
) => {
  const feature = machiningSetups?.[0]?.features?.find(
    (feature: any) => feature?.feature_id === featureId
  );
  const featureData = feature?.featureInformation?.feature_data;
  return (
    Object.keys(featureData?.feature_info ?? {}).length !== 1 ||
    featureData?.feature_info?.quantity !== 1
  );
};
