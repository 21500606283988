import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
declare const window: any;

export const useSiemensNxGenerateToolPathApi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [abortId, setAbortId] = useState<string>();

  const callApi = useCallback((type: string, args: any) => {
    const callbackId = "lfReactPluginEvents_" + uuid();
    const abortId = `abort_${callbackId}`;
    let timeoutId: NodeJS.Timeout;
    /**
     * We are using isResolved flag to prevent a possible race condition,
     * as we are working with event listeners and setTimeout.
     * The callbacks for these events are added to the callback queue,
     * and there is a possibility that callbacks for different
     * event types could be queued.
     * To handle this, isResolved ensures that the first event is marked as
     * true, while rejecting any subsequent events.
     * Since JavaScript is single-threaded, only one value of
     * isResolved can exist at a time.
     */
    let isResolved = false;

    // Resetting all state values to their original values before beginning the execution of this function's logic.
    setLoading(false);
    setError(null);
    setData(null);
    setAbortId(undefined);

    return new Promise<string>((resolve, reject) => {
      /**
       * Throw an error message when there is no response from CAM
       * within the specified time limit.
       */
      function timeoutEventListner() {
        if (!isResolved) {
          // Removing the event listeners as we have received a timeout status,
          // indicating that the process took too long to complete.
          window.removeEventListener(abortId, timeoutEventListner);
          window.removeEventListener(callbackId, handleReturnEvent);
          isResolved = true; // Resolving the promise to avoid race condition.
          const errorMessage = "The request has timed out.";
          setLoading(false);
          setData(null);
          setError(errorMessage);
          setAbortId(undefined);
          reject(new Error(errorMessage));
        }
      }

      /**
       * It will mark the ongoing promise as resolved and
       * send a signal to the CAM software to stop the execution as well.
       */
      function abortExecution() {
        if (!isResolved) {
          window.chrome.webview.postMessage({
            type: "stop_generate_tool_path",
            callbackId,
          });
          // Removing the event listeners as we have received a status indicating the end of the execution process.
          window.removeEventListener(abortId, timeoutEventListner);
          window.removeEventListener(callbackId, handleReturnEvent);
          clearTimeout(timeoutId); // Clearing out the timeout.
          setLoading(false);
          setData(null);
          setError(null);
          isResolved = true; // Resolving the promise to avoid race condition.
          // Since stopping the execution is initiated by the user, we can resolve the promise in this scenario.
          resolve("Tool path generation process was successfully terminated.");
        }
      }

      function handleReturnEvent(event: any) {
        if (!isResolved) {
          let response: any;
          try {
            response = JSON.parse(event.data);
            setData(response); // Updating the data state with the most recent response.
          } catch (e) {
            // An error occurred while parsing the JSON. We will use status code 1009 to indicate this.
            response = {
              status: 1009,
              message: "Error occured while trying to parse JSON",
            };
          } finally {
            // Removing the event listeners as we have received a status
            // indicating the end of the execution process. For other status
            // values, we will keep the promise and the event listener active.
            if ([1002, 1008, 1009].includes(response.status)) {
              window.removeEventListener(abortId, timeoutEventListner);
              window.removeEventListener(callbackId, handleReturnEvent);
              clearTimeout(timeoutId); // Clearing out the timeout.
              setLoading(false);
              setAbortId(undefined);
              isResolved = true; // Resolving the promise to avoid race condition.
              if (response.status === 1002 || response.status === 1009) {
                setError(response.message);
                reject(new Error(response.message));
              } else {
                setError(null);
                resolve(
                  "Tool path generation has been successfully completed."
                );
              }
            }
          }
        }
      }

      setLoading(true);
      if (window.chrome?.webview) {
        window.addEventListener(callbackId, handleReturnEvent);
        window.chrome.webview.postMessage({ type, args, callbackId });
        // Removing the event listeners after a specified threshold time
        // to minimize memory consumption in cases where no events are received.
        // (Current threshold: 10 minutes)
        timeoutId = setTimeout(() => {
          timeoutEventListner();
        }, 600000);

        setAbortId(abortId);
        // The following event listener gives the user the option to stop the execution prematurely.
        window.addEventListener(abortId, abortExecution);
      } else {
        setLoading(false);
        reject(
          new Error(
            "Please open a 3D model to use the following functionality."
          )
        );
      }
    });
  }, []);

  return [callApi as Function, loading, data, error, abortId];
};
