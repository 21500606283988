import { Checkbox } from "components/Checkbox";
import InValidCross from "assets/images/icons/uiw_stop-o.svg";
import { renderToString } from "react-dom/server";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { GlobalContext } from "context";
import clsx from "clsx";
import InValidToolPopUp from "components/Toolbox/InValidToolPopUp";
import EditCuttingParametersModal from "components/EditCuttingParameters";
import TooltipV2 from "components/ToolTipV2";

interface INoParamsToolbox {
  tool: any;
  confirmInvalidData: Function;
  index: number;
  selectedStep: any;
  boxData: any;
  setBoxData: Dispatch<SetStateAction<any>>;
  setSelectedTool: Function;
  selectedTool: any;
}

const fetchCommonLabels = (measurement_unit: string) => {
  return [
    { label: "Tool ID", value: "Tool ID", unit: "" },
    { label: "Tool material", value: "Body material code", unit: "" },
    { label: "Total Length", value: "Body length", unit: measurement_unit },
    {
      label: "Cutting Diameter",
      value: "Cutting diameter",
      unit: measurement_unit,
    },
    { label: "Flute Length", value: "Flute length", unit: measurement_unit },
    {
      label: "Shaft Diameter",
      value: "Shaft diameter",
      unit: measurement_unit,
    },
    { label: "Flute Count", value: "Flute count", unit: "" },
  ];
};

const fetchToolTipContent = (toolType: string, measurement_unit: string) => {
  let labelArray = [...fetchCommonLabels(measurement_unit)];
  switch (toolType) {
    case "face mill":
    case "slot mill":
    case "t-slot mill":
    case "bull nose end mill":
    case "boring bar":
    case "reamer":
      labelArray.push({
        label: "Corner Radius",
        value: "Corner radius",
        unit: measurement_unit,
      });
      break;
    case "chamfer mill":
    case "taper mill":
    case "tapered ball":
    case "tapered bull nose":
      labelArray.push({ label: "Taper Angle", value: "Taper angle", unit: "" });
      break;
    case "center drill":
    case "spot drill":
    case "drill":
      labelArray.push({ label: "Point angle", value: "Point angle", unit: "" });
      break;
    case "tap":
    case "tap right hand":
    case "thread former":
    case "thread mill":
      labelArray.push({
        label: "Thread Pitch",
        value: "Thread pitch",
        unit: measurement_unit,
      });
      break;
  }
  return labelArray;
};

const TooltipContent = ({
  tool,
  measurement_unit,
}: {
  tool: any;
  measurement_unit: string;
}) => {
  const TOOL_TYPE = tool?.["Tool type"];
  const LABEL_ARRAY = fetchToolTipContent(TOOL_TYPE, measurement_unit);
  return (
    <div>
      {LABEL_ARRAY.map(({ label, value, unit }, idx) =>
        !unit ? (
          <p key={idx}>{`${label}: ${tool?.[value] ?? "N/A"}`}</p>
        ) : (
          <p key={idx}>{`${label} (${unit}): ${tool?.[value] ?? "N/A"}`}</p>
        )
      )}
    </div>
  );
};

const NoParamsToolbox: React.FC<INoParamsToolbox> = ({
  tool,
  confirmInvalidData,
  index,
  selectedStep,
  boxData,
  setBoxData,
  setSelectedTool,
  selectedTool,
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip, projectInfo } = state;
  const measurementUnit: string = projectInfo?.unit;
  const isMarkedInvalid = tool?.isValid === false;
  const [showInvalidPopUp, setShowInvalidPopUp] = useState(false);
  const [showEditCuttingParamsModal, setShowEditCuttingParamsModal] =
    useState(false);

  const handleCheckTool = (flag: boolean) => {
    setSelectedTool(index, flag);
  };
  return (
    <div
      className={clsx(
        "shadow-input-2 min-w-[260px] max-w-[300px] flex flex-col flex-1 mr-2 border border-black border-opacity-10",
        {
          "border-[1.5px] border-solid border-[#F04438] !border-opacity-100":
            isMarkedInvalid,
        }
      )}
    >
      <div className="px-[10px] pt-[10px] mb-1">
        <div className="flex flex-row justify-between items-center mb-2">
          <div className="flex flex-row truncate">
            <Checkbox
              checked={selectedTool === tool?.["Tool ID"]}
              onChange={(val: boolean) => {
                handleCheckTool(val);
              }}
              type="yellow"
              disabled={tool?.isValid === false}
            />
            <TooltipV2
              title={
                <TooltipContent
                  tool={tool}
                  measurement_unit={measurementUnit}
                />
              }
              maxWidth="300px"
            >
              <span
                className="font-semibold text-sm text-black truncate ml-2"
                id={tool?.["Tool ID"]}
              >
                {tool?.["Tool ID"] || "N/A"}
              </span>
            </TooltipV2>
          </div>
          <div>
            <button
              className="inline-block w-[18px] h-[18px]"
              onClick={() => setShowInvalidPopUp(true)}
              id={`${tool?.["Tool ID"]}-invalidButton`}
              onMouseOver={() => {
                if (!isMarkedInvalid) {
                  showTooltip(`${tool?.["Tool ID"]}-invalidButton`);
                }
              }}
              onMouseLeave={() => hideTooltip()}
              data-tooltip-html={renderToString(
                <p className="font-medium text-base leading-[16.94px]">
                  Mark as Invalid
                </p>
              )}
            >
              <img src={InValidCross} alt="InValid" />
            </button>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "h-full flex justify-center items-center flex-col p-[24px] gap-[8px]",
          {
            "opacity-60": isMarkedInvalid,
          }
        )}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.5 10H35.5M27.5 6V14M27.5 10H5.5M13.5 24H5.5M21.5 20V28M43.5 24H21.5M41.5 38H35.5M27.5 34V42M27.5 38H5.5"
            stroke="#6B7280"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="text-[14px] font-semibold text-gray-600">
          No Parameters Available.
        </div>
        <div className="text-[12px] font-medium text-center text-gray-500">
          Parameters for this tool are not pre-configured. Please add your
          custom parameters.
        </div>
        <button
          aria-label="button"
          disabled={isMarkedInvalid}
          onClick={() => setShowEditCuttingParamsModal(true)}
          className={clsx(
            "inline-flex items-center gap-[8px] px-[16px] py-[8px] rounded-[4px] bg-blue-600 text-white text-[12px] font-semibold",
            {
              "hover:bg-blue-500": !isMarkedInvalid,
            }
          )}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.0026 3.33398V12.6673M3.33594 8.00065H12.6693"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add
        </button>
      </div>
      <InValidToolPopUp
        showPopUp={showInvalidPopUp}
        closePopUp={() => setShowInvalidPopUp(false)}
        tool={{ ...tool }}
        confirmInValidData={confirmInvalidData}
      />
      <EditCuttingParametersModal
        isAddModal={true}
        data={tool.additional_info}
        isOpen={showEditCuttingParamsModal}
        setIsOpen={setShowEditCuttingParamsModal}
        toolId={tool?.["Tool ID"]}
        selectedStep={selectedStep}
        boxData={boxData}
        setBoxData={setBoxData}
        index={index}
      />
    </div>
  );
};

export default NoParamsToolbox;
