const StepIcon: React.FC<{ visited: boolean }> = ({ visited }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        fill="white"
        stroke={visited ? "#1570EF" : "#98A2B3"}
      />
      <circle cx="8" cy="8" r="3" fill={visited ? "#1570EF" : "#98A2B3"} />
    </svg>
  );
};

export default StepIcon;
