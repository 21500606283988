import { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import {
  EDGE_DATA,
  FACE_DATA,
  FEATURE_LIST,
  HOLE_DATA,
  POCKET_DATA,
  GROOVE_DATA,
  SET_LOADING_BAR,
  SET_MACHINE_SETUP,
  SET_ORIGIN_MACHINE_SETUP,
  SET_SETUP_NUMBER,
  SET_STREAM_STEP,
  keyList,
  SET_ASSIGNED_SETUPS,
  SET_SETUP_MAPPING,
  SET_VISIBLE_IDS,
  SET_FEATURE_MAPPING,
  SET_LOADING_STYLE,
} from "constant";
import AddYellowCircleIcon from "assets/images/icons/add-yellow-circle-icon.svg";
import AccordionItem from "components/AccordionItem";
import HoleForm from "./HoleForm";
// import ThreadHoleForm from "./HoleForm/ThreadHole";
import FaceForm from "./FaceForm";
import PocketForm from "./PocketForm";
import EdgeForm from "./EdgeForm";
import Button from "components/Button";
import { GlobalContext } from "context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  useApiCall,
  useLocalFusionApiCall,
  useLocalMasterCamApiCall,
  useLocalSiemensNxApiCall,
} from "hooks";
import { getUserSelectionAPI } from "services";
import GrooveForm from "./GrooveForm";
import {
  simpleHoleFeatureDataValidation,
  threadHoleFeatureDataValidation,
  topFaceFeatureDataValidation,
  bottomFaceFeatureDataValidation,
  sideFaceFeatureDataValidation,
  pocketFeatureDataValidation,
  filletFeatureDataValidation,
  slotFeatureDataValidation,
  chamferFeatureDataValidation,
  grooveFeaureDataValidation,
  simpleHoleFeatureValidation,
  capitalize,
  threadHoleFeatureValidation,
  topFaceFeatureValidation,
  bottomFaceFeatureValidation,
  sideFaceFeatureValidation,
  pocketFeatureValidation,
  slotFeatureValidation,
  filletFeatureValidation,
  chamferFeatureValidation,
  grooveFeaureValidation,
  createDeepCopy,
  notifyCAM,
} from "utils";
import FeatureInputErrorCountModal from "components/FeatureInputsErrorCountModal";
import { toast } from "react-toastify";
import { ICoordinateSystem, IFeatureItem, IPositionInfo } from "./utils";
import AutomaticFeatureRecognitionSelectionModal from "./AutomaticFeatureRecognitionSelectionModal";
import DisplayCamSpecificFeatureName from "components/DisplayCamSpecificFeatureName";

const FeatureItem: React.FC<IFeatureItem> = ({ item, selected, onSelect }) => {
  const classes = clsx({
    "rounded border-solid border-2 px-4 py-3 flex flex-row items-center my-2 cursor-pointer":
      true,
    "bg-surface-secondary border-surface-default": selected === item?.type,
    "bg-white border-surface-secondary": selected !== item?.type,
  });

  const textClass = clsx({
    "font-semibold text-xs": true,
    "text-black": selected !== item?.type,
    "text-surface-default": selected === item?.type,
  });
  const IconView = item?.icon;
  return (
    <div className={classes} onClick={() => onSelect(item?.type)}>
      <div className="mr-2">
        {<IconView active={selected === item?.type} />}
      </div>
      <span className={textClass}>{item?.sectionTitle}</span>
    </div>
  );
};

const FeatureRecognition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(GlobalContext);
  const {
    showTooltip,
    hideTooltip,
    projectInfo,
    setupNumber,
    machiningSetups,
  } = state;
  const [open, setOpen] = useState(false);
  const [getSelection, isLoading] = useApiCall(getUserSelectionAPI);
  const [selectedItem, setSelectedItem] = useState("hole");
  const [selectedFeature, setSelectedFeature] = useState<any>(null);
  const [fetchFusionData, fusionloading, fusionData, fusionError] =
    useLocalFusionApiCall();
  const [fetchSiemensNxData, siemensNxLoading, siemensNxData, siemensNxError] =
    useLocalSiemensNxApiCall();
  const [fetchMasterCamData, MasterCamLoading, MasterCamData, MasterCamError] =
    useLocalMasterCamApiCall();
  const [errorCounts, setErrorCounts] = useState<any>([]);
  const [openFeatureInputErrorCountModal, setOpenFeatureInputErrorCountModal] =
    useState(false);
  const [selectedFeatureForm, setSelectedFeatureForm] = useState<{
    featureId?: string;
    tagId?: string;
    showErrors?: boolean;
  }>({});

  useEffect(() => {
    if (!projectInfo || !Object.keys(projectInfo)?.length) {
      navigate("/projects");
      return;
    }
    if (!location?.state?.load) return;

    if (!projectInfo?.userSelectionId) {
      const newSetup = {
        machiningSetupNumber: 0,
        toolLibraryId: "",
        toolLibraryName: "",
        features: [],
      };
      dispatch({
        type: SET_MACHINE_SETUP,
        payload: [newSetup],
      });
      // creating default setup with zero assigned operations
      const numberOfSetups = projectInfo?.numberOfSetups ?? 1;
      let newAssignedSetups = {};
      let newSetupsMapping = {};
      for (let i = 1; i <= numberOfSetups; i++) {
        const setupId = "setupId" + uuid();
        newAssignedSetups = {
          ...newAssignedSetups,
          [setupId]: [],
        };
        newSetupsMapping = {
          ...newSetupsMapping,
          [setupId]: {
            index: i - 1,
            name: `OP${i * 10}`,
            programName: `P${i}`,
          },
        };
      }
      dispatch({
        type: SET_ASSIGNED_SETUPS,
        payload: newAssignedSetups,
      });
      dispatch({
        type: SET_SETUP_MAPPING,
        payload: newSetupsMapping,
      });
      dispatch({
        type: SET_SETUP_NUMBER,
        payload: 0,
      });
      dispatch({
        type: SET_LOADING_STYLE,
        payload: "",
      });
      return;
    }
    getSelection({
      organizationId: projectInfo?.organizationId,
      selectionId: projectInfo?.userSelectionId,
    })
      .then((res: any) => {
        if (!res) {
          dispatch({
            type: SET_MACHINE_SETUP,
            payload: [],
          });
          dispatch({
            type: SET_ASSIGNED_SETUPS,
            payload: {},
          });
          dispatch({
            type: SET_SETUP_MAPPING,
            payload: {},
          });
          dispatch({
            type: SET_ORIGIN_MACHINE_SETUP,
            payload: [],
          });
          dispatch({
            type: SET_STREAM_STEP,
            payload: null,
          });
          return;
        }
        // ---------------------------------------------------------------------
        // IMTS CHANGE
        const newMachiningSetups = JSON.parse(
          JSON.stringify(res?.machiningSetups ?? [])
        );
        if (res?.streamStep === "initial-trigger") {
          newMachiningSetups?.forEach((setupInfo: any) => {
            for (const feature of setupInfo?.features ?? []) {
              delete feature?.featureInformation?.strategies;
            }
          });
        }
        // ---------------------------------------------------------------------
        dispatch({
          type: SET_MACHINE_SETUP,
          payload: [...newMachiningSetups],
        });
        dispatch({
          type: SET_ORIGIN_MACHINE_SETUP,
          payload: [...newMachiningSetups],
        });
        //setting assignedSetups object
        if (res?.assignedMachiningSetups) {
          Object.keys(res.assignedMachiningSetups).forEach((key: string) => {
            res.assignedMachiningSetups[key].forEach((item: any) => {
              item.strategyIndex = 0;
            });
          });
        }
        dispatch({
          type: SET_ASSIGNED_SETUPS,
          payload: { ...res?.assignedMachiningSetups },
        });
        // sort mapping object
        const sortedEntries: any = Object.entries(
          res?.mappingForMachiningSetups ?? {}
        )?.sort(([, a], [, b]) => (a as any).index - (b as any).index);
        const sortedMappingForMachiningSetups =
          Object.fromEntries(sortedEntries) ?? {};
        dispatch({
          type: SET_SETUP_MAPPING,
          payload: { ...sortedMappingForMachiningSetups },
        });
        dispatch({
          type: SET_STREAM_STEP,
          payload: res?.streamStep,
        });
        if (res?.machiningSetups?.length) {
          dispatch({
            type: SET_SETUP_NUMBER,
            payload: res?.machiningSetups?.[0]?.machiningSetupNumber,
          });
        }
        if (
          // These 3 conditions are required to make sure older projects continue to work as intended.
          res?.streamStep === "params-complete" &&
          res?.visiblefeature_ids &&
          res?.featureMapping
        ) {
          dispatch({
            type: SET_VISIBLE_IDS,
            payload: {
              pageMapping: "0",
              ids: res.visiblefeature_ids["0"],
            },
          });
          dispatch({
            type: SET_LOADING_BAR,
            payload: false,
          });
          dispatch({
            type: SET_FEATURE_MAPPING,
            payload: res?.featureMapping,
          });
          setTimeout(() =>
            navigate("/project?tab=summary", {
              state: { referrer: "feature-recognition" },
            })
          );
        }
      })
      .catch(() => {
        dispatch({
          type: SET_MACHINE_SETUP,
          payload: [],
        });
        dispatch({
          type: SET_ORIGIN_MACHINE_SETUP,
          payload: [],
        });
        dispatch({
          type: SET_STREAM_STEP,
          payload: null,
        });
        dispatch({
          type: SET_ASSIGNED_SETUPS,
          payload: {},
        });
        dispatch({
          type: SET_SETUP_MAPPING,
          payload: {},
        });
      })
      .finally(() => {
        dispatch({
          type: SET_LOADING_STYLE,
          payload: "",
        });
      });
  }, [projectInfo]);

  useEffect(() => {
    if (!machiningSetups?.length || setupNumber < 0) {
      setSelectedFeature({});
      return;
    }
    const selected = machiningSetups[0];
    setSelectedFeature(selected);
    if (selectedItem === "hole") {
      for (const { type } of keyList) {
        if (
          selected?.features?.find(
            (feature: any) =>
              feature?.featureInformation?.feature_data?.feature_type === type
          )
        ) {
          setSelectedItem(type);
          break;
        }
      }
    }
  }, [machiningSetups]);

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
    } else {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (selectedFeatureForm?.tagId) {
      notifyCAM("highlight-feature", { tag_id: selectedFeatureForm.tagId });
    }
  }, [selectedFeatureForm]);
  /**
   * @todo Fix fusion working properly
   */
  const autoRecognizeFeatures = async (featureTypesArray: string[]) => {
    try {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });

      let autoRecognizedFeaturesArray;
      switch (projectInfo?.cam) {
        case "mastercam":
          autoRecognizedFeaturesArray = await fetchMasterCamData(
            "feature-recoginition",
            {}
          );
          break;
        case "fusion360":
          // TODO: Fix fusion working properly
          // autoRecognizedFeaturesArray = await fetchFusionData(
          //   "feature-recoginition",
          //   {}
          // );
          break;
        case "simensNx":
        default:
          autoRecognizedFeaturesArray = await fetchSiemensNxData(
            "feature-recoginition",
            { selected_feature_types: featureTypesArray }
          );
          break;
      }

      const updated_setups = [...machiningSetups];

      const setupIdx = updated_setups?.findIndex(
        (setup: any) => setup?.machiningSetupNumber === setupNumber
      );

      const materialInfo = {
        material: projectInfo?.material || "p | steel",
        sub_material: projectInfo?.subMaterial || "alloy steel",
      };

      const machineInfo = {
        name: projectInfo?.machineName || "Hermle c800",
        rpm: projectInfo?.max_spindle_speed?.max_spindle_speed || 1000,
        axes: projectInfo?.numberOfAxis?.toString() || "3",
      };

      updated_setups[setupIdx].features = updated_setups[
        setupIdx
      ].features.filter(
        (feature: any) =>
          !featureTypesArray.includes(
            feature?.featureInformation?.feature_data?.feature_type
          )
      );

      updated_setups[setupIdx].features.push(
        ...autoRecognizedFeaturesArray
          .map((feature: any) => {
            const {
              tag_id,
              feature_name,
              feature_type,
              coordinate_system,
              position_info,
              cam_specific_names,
              ...featureInfo
            } = feature;
            if (
              !tag_id ||
              !feature_name ||
              !feature_type
              // !coordinate_system ||
              // !position_info
            )
              return null;
            return constructFeatureData(
              tag_id?.toString(),
              feature_name,
              feature_type,
              featureInfo,
              materialInfo,
              machineInfo,
              coordinate_system,
              position_info,
              cam_specific_names || []
            );
          })
          .filter((feature: any) => !!feature)
      );

      dispatch({
        type: SET_MACHINE_SETUP,
        payload: updated_setups,
      });
    } catch (err: any) {
      if (err?.message) {
        toast.error(err.message);
      }
    } finally {
      dispatch({
        type: SET_LOADING_BAR,
        payload: false,
      });
    }
  };

  function constructFeatureData(
    tagId: number,
    featureName: string,
    featureType: string,
    featureInfo: any,
    materialInfo: any,
    machineInfo: any,
    coordinate_system: ICoordinateSystem | undefined,
    position_info: IPositionInfo[] | undefined,
    camSpecificName: string[]
  ) {
    const featureId = uuid();
    let newFeature: any = {
      feature_id: featureId,
      featureInformation: {
        // tag_id, coordinate_system and position_info will only be present for auto recognized features
        tag_id: tagId,
        // coordinate_system,
        // position_info,
        cam_specific_names: camSpecificName,
        feature_data: {
          feature_id: featureId,
          material_info: materialInfo,
          machine_info: machineInfo,
          feature_name: featureName,
          feature_type: featureType,
          feature_info: {},
        },
        strategies: [],
      },
    };
    let validationSchema;
    let validationResult;
    let context: any = {};
    try {
      switch (true) {
        case featureName === "simple_hole" && featureType === "hole":
          validationSchema = simpleHoleFeatureDataValidation();
          break;
        case featureName === "thread_hole" && featureType === "hole":
          validationSchema = threadHoleFeatureDataValidation();
          break;
        case featureName === "top_face" && featureType === "face":
          validationSchema = topFaceFeatureDataValidation();
          break;
        case featureName === "bottom_face" && featureType === "face":
          validationSchema = bottomFaceFeatureDataValidation();
          break;
        case featureName === "side_face" && featureType === "face":
          validationSchema = sideFaceFeatureDataValidation();
          break;
        case featureName === "pocket_2d" && featureType === "pocket":
          validationSchema = pocketFeatureDataValidation();
          break;
        case featureName === "fillet" && featureType === "edge":
          validationSchema = filletFeatureDataValidation();
          break;
        case featureName === "slot" && featureType === "pocket":
          validationSchema = slotFeatureDataValidation();
          break;
        case featureName === "chamfer" && featureType === "edge":
          validationSchema = chamferFeatureDataValidation();
          break;
        case featureName === "groove" && featureType === "groove":
          validationSchema = grooveFeaureDataValidation();
          break;

        default:
          throw new Error("Invalid feature name and type provided.");
      }
      validationResult = validationSchema?.validateSync(featureInfo, {
        abortEarly: true,
        stripUnknown: true,
        context,
      });
      if (!validationResult) throw new Error("Unable to get Validation Result");
      // Deleting null values in feature inputs
      for (const inputField in validationResult) {
        if (validationResult[inputField] === null)
          delete validationResult[inputField];
      }
      // Setting default value for quantity as 1 when it is null or undefined.
      validationResult.quantity = validationResult.quantity ?? 1;

      newFeature.featureInformation.feature_data.feature_info =
        validationResult;
    } catch (error) {
      newFeature = null;
    }
    return newFeature;
  }

  const addHole = () => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    let updated_features = updated_setups?.[setupIdx]?.features;

    const material_info = {
      material: projectInfo?.material || "p | steel",
      sub_material: projectInfo?.subMaterial || "alloy steel",
    };
    const machine_info = {
      name: projectInfo?.machineName || "Hermle c800",
      rpm: projectInfo?.max_spindle_speed?.max_spindle_speed || 1000,
      axes: projectInfo?.numberOfAxis?.toString() || "3",
    };

    // There should be no undefined values when finally pushing the new feature.
    let featureId, featureName, featureType;
    let newFeature: any = {
      feature_id: undefined,
      featureInformation: {
        feature_data: {
          feature_id: undefined,
          feature_name: undefined,
          feature_type: undefined,
          material_info,
          machine_info,
          // Setting default value for quantity.
          feature_info: { quantity: 1 },
        },
        strategies: [],
      },
    };

    switch (selectedItem) {
      case "hole":
        featureId = `${HOLE_DATA?.type}_${uuid()}`;
        featureName = HOLE_DATA?.type;
        featureType = "hole";
        break;
      case "face":
        featureId = `${FACE_DATA?.type?.toLowerCase()}_${uuid()}`;
        featureName = FACE_DATA?.type;
        featureType = "face";
        break;
      case "pocket":
        featureId = `${POCKET_DATA?.type}_${uuid()}`;
        featureName = POCKET_DATA?.type;
        featureType = "pocket";
        break;
      case "edge":
        featureId = `${EDGE_DATA?.type?.toLowerCase()}_${uuid()}`;
        featureName = EDGE_DATA?.type;
        featureType = "edge";
        break;
      case "groove":
        featureId = `${GROOVE_DATA?.type}_${uuid()}`;
        featureName = GROOVE_DATA?.type;
        featureType = "groove";
        break;
      // case "3d-core":
      //   dispatch({
      //     type: SET_CORE_CAVITY_LIST,
      //     payload: [
      //       ...coreCavityList,
      //       { ...CORE_CAVITY_DATA, id: `core_${uuid()}` },
      //     ],
      //   });
      //   break;
      // case "3d-pencil":
      //   dispatch({
      //     type: SET_PENCIL_LIST,
      //     payload: [...pencilList, { ...PENCIL_DATA, id: `pencil_${uuid()}` }],
      //   });
      //   break;
    }

    newFeature.feature_id = featureId;
    newFeature.featureInformation.feature_data.feature_id = featureId;
    newFeature.featureInformation.feature_data.feature_name = featureName;
    newFeature.featureInformation.feature_data.feature_type = featureType;
    updated_features.push(newFeature);
    updated_setups[setupIdx].features = updated_features;
    setSelectedFeatureForm({ featureId: newFeature.feature_id });
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  const handleRemove = (feature_id: string) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    if (feature_idx < 0) return;
    updated_setups?.[0]?.features?.splice(feature_idx, 1);
    // updated_setups[setupIdx].features = updated_features;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  const validationCheck = () => {
    let features = machiningSetups?.[0]?.features;
    let updated_setups = createDeepCopy(machiningSetups);
    let featureMap: any = {
      hole: 0,
      face: 0,
      pocket: 0,
      edge: 0,
      groove: 0,
    };
    let errorCounts: any = [];
    features.forEach((feature: any, ind: number) => {
      const feature_data = feature?.featureInformation?.feature_data;
      const featureName = feature_data?.feature_name;
      const featureType = feature_data?.feature_type;
      const context: any = {};
      let validationSchema: any;
      featureMap[featureType] += 1;
      switch (true) {
        case featureName === "simple_hole" && featureType === "hole":
          context.isFinishTypeCustom =
            !("finish_type" in feature_data.feature_info) ||
            projectInfo?.unit === "in";
          validationSchema = simpleHoleFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "thread_hole" && featureType === "hole":
          context.isFinishTypeCustom =
            !("finish_type" in feature_data.feature_info) ||
            projectInfo?.unit === "in";
          validationSchema = threadHoleFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "top_face" && featureType === "face":
          validationSchema = topFaceFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "bottom_face" && featureType === "face":
          validationSchema = bottomFaceFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "side_face" && featureType === "face":
          validationSchema = sideFaceFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "pocket_2d" && featureType === "pocket":
          context.isCornerRadius =
            feature_data?.feature_info?.corner_radius !== null &&
            feature_data?.feature_info?.corner_radius !== undefined;
          validationSchema = pocketFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "fillet" && featureType === "edge":
          validationSchema = filletFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "slot" && featureType === "pocket":
          validationSchema = slotFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "chamfer" && featureType === "edge":
          validationSchema = chamferFeatureValidation(projectInfo?.unit);
          break;
        case featureName === "groove" && featureType === "groove":
          validationSchema = grooveFeaureValidation(projectInfo?.unit);
          break;
        default:
          throw new Error("Invalid feature name and type provided.");
      }

      try {
        let validationResult = validationSchema?.validateSync(
          createDeepCopy(
            feature?.featureInformation?.feature_data?.feature_info
          ),
          {
            abortEarly: false,
            stripUnknown: true,
            context,
          }
        );
        if (!validationResult)
          throw new Error("Unable to get Validation Result");
        else {
          updated_setups[0].features[
            ind
          ].featureInformation.feature_data.feature_info = validationResult;
        }
      } catch (error: any) {
        if (error?.inner?.length) {
          errorCounts.push({
            type: featureType,
            featureId: feature.feature_id,
            tagId: feature.featureInformation?.tag_id,
            title: `${capitalize(featureType)} ${featureMap[featureType]}`,
            count: error.inner?.length,
          });
        }
      }
    });
    setErrorCounts(errorCounts);
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    if (errorCounts?.length) {
      setOpenFeatureInputErrorCountModal(true);
    } else {
      navigate(`/project?tab=feature-summary`, {
        state: {
          referrer: "feature",
        },
      });
    }
  };

  const handleRedirect = (tab: string) => {
    if (tab === "feature-summary") validationCheck();
    else navigate(`/project?tab=${tab}`);
  };

  const handleBackToProjectList = () => {
    navigate(`/projects`);
  };
  const featuresAvailable: boolean =
    machiningSetups?.[setupNumber]?.features?.length > 0;

  return (
    <div className="flex px-4 h-full">
      <div className="w-[20%] flex h-full flex-row space-x-2">
        {/* Removing functionlity to edit setups  */}
        {/* <ProjectItemList /> */}
        <div className="w-full overflow-y-auto h-full flex flex-col justify-between bg-gray-f9f p-1 rounded-md border border-gray-eac">
          <div className="w-full">
            <p className="font-medium text-gray-475 text-[10px]">
              Supported Features
            </p>
            <div className="pr-1">
              {FEATURE_LIST.map((item: any, idx) => (
                <FeatureItem
                  item={item}
                  key={idx}
                  selected={selectedItem}
                  onSelect={(type: string) => {
                    setSelectedItem(type);
                    setSelectedFeatureForm({ featureId: "" });
                  }}
                />
              ))}
            </div>
          </div>
          <div>
            <button
              disabled={projectInfo?.cam_software !== "siemens"}
              className={clsx(
                "border-2 border-dashed rounded border-surface-default text-surface-default text-xs font-semibold py-2 w-full flex items-center px-4 justify-center",
                {
                  "cursor-not-allowed opacity-50":
                    projectInfo?.cam_software !== "siemens",
                }
              )}
              onClick={() => setOpen(true)}
            >
              <img
                src={AddYellowCircleIcon}
                alt="add icon"
                width="16"
                className="mr-2"
              />{" "}
              Automatic Feature Recognition
            </button>
          </div>
        </div>
      </div>
      <div className="w-[80%] px-4 flex flex-row relative pt-0.5">
        <div className="w-full mr-2 pr-2 mt-px overflow-y-auto h-[calc(100%-65px)]">
          {selectedItem === "hole" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "hole"
              )
              ?.map((hole: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  checked={selectedFeatureForm.featureId === hole?.feature_id}
                  labelClass={"h-[36px] pl-2"}
                  onChange={(e: any) => {
                    if (e) {
                      setSelectedFeatureForm({
                        featureId: hole?.feature_id,
                        tagId: hole?.featureInformation?.tag_id,
                      });
                    } else setSelectedFeatureForm({ featureId: "" });
                  }}
                  dragIcon={false}
                  tabLabelChild={
                    <div className="inline-flex whitespace-nowrap overflow-hidden w-full">
                      {`${idx + 1}. Hole `}
                      <DisplayCamSpecificFeatureName
                        namesArray={hole.featureInformation?.cam_specific_names}
                      />
                    </div>
                  }
                  className="mb-2"
                  removeHole={() => handleRemove(hole?.feature_id)}
                >
                  <HoleForm
                    key={idx}
                    feature_id={hole?.feature_id}
                    setAllFieldsTouched={
                      selectedFeatureForm.featureId === hole?.feature_id &&
                      selectedFeatureForm.showErrors
                    }
                  />
                </AccordionItem>
              ))}
          {/* {selectedItem === "thread_hole" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "thread_hole"
              )
              ?.map((hole: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  tabLabelChild={`${idx + 1}. Thread Hole`}
                  className="mb-2"
                  removeHole={() => handleRemove(hole?.feature_id)}
                >
                  <ThreadHoleForm key={idx} feature_id={hole?.feature_id} />
                </AccordionItem>
              ))} */}
          {selectedItem === "face" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "face"
              )
              ?.map((face: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  labelClass={"h-[36px] pl-2"}
                  dragIcon={false}
                  checked={selectedFeatureForm.featureId === face?.feature_id}
                  onChange={(e: any) => {
                    if (e)
                      setSelectedFeatureForm({
                        featureId: face?.feature_id,
                        tagId: face?.featureInformation?.tag_id,
                      });
                    else setSelectedFeatureForm({ featureId: "" });
                  }}
                  tabLabelChild={
                    <div className="inline-flex whitespace-nowrap overflow-hidden  w-full">
                      {`${idx + 1}. Face `}
                      <DisplayCamSpecificFeatureName
                        namesArray={face.featureInformation?.cam_specific_names}
                      />
                    </div>
                  }
                  className="mb-2"
                  removeHole={() => handleRemove(face?.feature_id)}
                >
                  <FaceForm
                    key={idx}
                    feature_id={face?.feature_id}
                    setAllFieldsTouched={
                      selectedFeatureForm.featureId === face?.feature_id &&
                      selectedFeatureForm.showErrors
                    }
                  />
                </AccordionItem>
              ))}

          {selectedItem === "pocket" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "pocket"
              )
              ?.map((pocket: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={pocket.feature_id}
                  labelClass={"h-[36px] pl-2"}
                  dragIcon={false}
                  checked={selectedFeatureForm.featureId === pocket?.feature_id}
                  onChange={(e: any) => {
                    if (e)
                      setSelectedFeatureForm({
                        featureId: pocket?.feature_id,
                        tagId: pocket?.featureInformation?.tag_id,
                      });
                    else setSelectedFeatureForm({ featureId: "" });
                  }}
                  tabLabelChild={
                    <div className="inline-flex whitespace-nowrap overflow-hidden  w-full">
                      {`${idx + 1}. Pocket `}
                      <DisplayCamSpecificFeatureName
                        namesArray={
                          pocket.featureInformation?.cam_specific_names
                        }
                      />
                    </div>
                  }
                  className="mb-2"
                  removeHole={() => handleRemove(pocket?.feature_id)}
                >
                  <PocketForm
                    key={idx}
                    feature_id={pocket?.feature_id}
                    setAllFieldsTouched={
                      selectedFeatureForm.featureId === pocket?.feature_id &&
                      selectedFeatureForm.showErrors
                    }
                  />
                </AccordionItem>
              ))}

          {selectedItem === "edge" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "edge"
              )
              ?.map((edge: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  labelClass={"h-[36px] pl-2"}
                  dragIcon={false}
                  checked={selectedFeatureForm.featureId === edge?.feature_id}
                  onChange={(e: any) => {
                    if (e)
                      setSelectedFeatureForm({
                        featureId: edge?.feature_id,
                        tagId: edge?.featureInformation?.tag_id,
                      });
                    else setSelectedFeatureForm({ featureId: "" });
                  }}
                  tabLabelChild={
                    <div className="inline-flex whitespace-nowrap overflow-hidden  w-full">
                      {`${idx + 1}. Edge `}
                      <DisplayCamSpecificFeatureName
                        namesArray={edge.featureInformation?.cam_specific_names}
                      />
                    </div>
                  }
                  className="mb-2"
                  removeHole={() => handleRemove(edge?.feature_id)}
                >
                  <EdgeForm
                    key={idx}
                    feature_id={edge?.feature_id}
                    setAllFieldsTouched={
                      selectedFeatureForm.featureId === edge?.feature_id &&
                      selectedFeatureForm.showErrors
                    }
                  />
                </AccordionItem>
              ))}
          {selectedItem === "groove" &&
            selectedFeature?.features
              ?.filter(
                (feature: any) =>
                  feature?.featureInformation?.feature_data?.feature_type ===
                  "groove"
              )
              ?.map((groove: any, idx: number) => (
                <AccordionItem
                  index={idx}
                  key={idx}
                  labelClass={"h-[36px] pl-2"}
                  dragIcon={false}
                  checked={selectedFeatureForm.featureId === groove?.feature_id}
                  onChange={(e: any) => {
                    if (e)
                      setSelectedFeatureForm({
                        featureId: groove?.feature_id,
                        tagId: groove?.featureInformation?.tag_id,
                      });
                    else setSelectedFeatureForm({ featureId: "" });
                  }}
                  tabLabelChild={
                    <div className="inline-flex whitespace-nowrap overflow-hidden  w-full">
                      {`${idx + 1}. Groove `}
                      <DisplayCamSpecificFeatureName
                        namesArray={
                          groove.featureInformation?.cam_specific_names
                        }
                      />
                    </div>
                  }
                  className="mb-2"
                  removeHole={() => handleRemove(groove?.feature_id)}
                >
                  <GrooveForm
                    key={idx}
                    feature_id={groove?.feature_id}
                    setAllFieldsTouched={
                      selectedFeatureForm.featureId === groove?.feature_id &&
                      selectedFeatureForm.showErrors
                    }
                  />
                </AccordionItem>
              ))}
          {/* {selectedItem === "3d-core" &&
            coreCavityList?.map((coreCavity: any, idx: number) => (
              <AccordionItem
                index={idx}
                key={idx}
                tabLabelChild={`${idx + 1}. 3D Core & Cavity`}
                className="mb-2"
                removeHole={handleRemove}
              >
                <CoreCavity index={idx} />
              </AccordionItem>
            ))}

          {selectedItem === "3d-pencil" &&
            pencilList?.map((pencil: any, idx: number) => (
              <AccordionItem
                index={idx}
                key={idx}
                tabLabelChild={`${idx + 1}. 3D Pencil`}
                className="mb-2"
                removeHole={handleRemove}
              >
                <PencilForm index={idx} />
              </AccordionItem>
            ))} */}
        </div>
        <button
          className="rounded w-[52px] bg-blue-d1e h-[46px] "
          onClick={() => addHole()}
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="m-auto"
          >
            <path
              d="M9.173 14.6663V7.99967M9.173 7.99967V1.33301M9.173 7.99967H16.8226M9.173 7.99967H1.52344"
              stroke="#1570EF"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>

        <div className="w-full absolute bottom-[4px] left-0 right-0 bg-white flex justify-end items-center py-2 border-t border-gray-e7e">
          <div className="flex flex-row font-semibold gap-4">
            <Button variant="gray" onClick={() => handleBackToProjectList()}>
              Back
            </Button>
            <div
              className={clsx({
                "cursor-not-allowed": !featuresAvailable,
              })}
              id="feature-btn"
              onMouseOver={() =>
                !featuresAvailable && showTooltip("feature-btn")
              }
              onMouseLeave={() => hideTooltip()}
              data-tooltip-content="You must enter at least one feature to Continue"
            >
              <Button
                className={clsx({
                  "pointer-events-none": !featuresAvailable,
                })}
                onClick={() => handleRedirect("feature-summary")}
                disabled={!featuresAvailable}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
      <FeatureInputErrorCountModal
        isOpen={openFeatureInputErrorCountModal}
        setIsOpen={setOpenFeatureInputErrorCountModal}
        errorCounts={errorCounts}
        setSelectedItem={setSelectedItem}
        setErrorFeature={setSelectedFeatureForm}
      />
      <AutomaticFeatureRecognitionSelectionModal
        isOpen={open}
        setIsOpen={setOpen}
        handleContinue={autoRecognizeFeatures}
      />
    </div>
  );
};

export default FeatureRecognition;
