import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ToolpathGenerationSuccessfulModal from "components/ToolPathGenerationSuccessfulModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControlLabel,
  FormGroup,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import Button from "components/Button";
import Checkbox from "@mui/material/Checkbox";
import { useApiCall } from "hooks";
import { updateUserAPI } from "services";
import { GlobalContext } from "context";
import { updateUserFlag } from "utils";

interface IGenerateToolPathProgress {
  siemensLoading: boolean;
  siemensResponse: {
    status: number;
    message:
      | string
      | {
          complete: number;
          total: number;
        };
  };
  abortEventId: string;
}

const CustomLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "rgb(21, 112, 239)",
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgb(255, 255, 255)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "black",
    maxWidth: "340px",
    margin: "12px",
    padding: "16px",
    borderRadius: "8px !important",
  },
}));

export default function GenerateToolPathProgress({
  siemensLoading,
  siemensResponse,
  abortEventId,
}: Readonly<IGenerateToolPathProgress>) {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const [progress, setProgress] = useState(0);
  const [displayMessage, setDisplayMessage] = useState<string>();
  const [open, setOpen] = useState(false);
  const [hideAbortToolTip, setHideAbortToolTip] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(
    !userInfo.userFlags?.hideGenerateToolPathToolTip
  );
  const [updateUser] = useApiCall(updateUserAPI);

  useEffect(() => {
    switch (siemensResponse?.status) {
      case 1001:
        setDisplayMessage("Initializing Tool and Toolpath Generation");
        break;
      case 1003:
        setDisplayMessage("Tool generation: Initializing");
        break;
      case 1004:
        if (typeof siemensResponse?.message === "object") {
          const { complete, total } = siemensResponse.message;
          const progressPercentage = (+complete / +total) * 100;
          setProgress(Math.round(progressPercentage / 2));
          setDisplayMessage(
            `Tool generation: In Progress (${progressPercentage}%)`
          );
        }
        break;
      case 1005:
        setDisplayMessage("Tool generation: Complete");
        break;
      case 1006:
        setDisplayMessage("Tool Path generation: Initializing");
        break;
      case 1007:
        if (typeof siemensResponse?.message === "object") {
          const { complete, total } = siemensResponse.message;
          const progressPercentage = (+complete / +total) * 100;
          setProgress(Math.round(50 + progressPercentage / 2));
          setDisplayMessage(
            `Tool Path generation: In Progress (${progressPercentage}%)`
          );
        }
        break;
      case 1008:
        setProgress(0); // Reset the progress back to zero.
        setDisplayMessage("Tool Path generation: Complete");
        setOpen(true); // Open the modal indicating to the user that toolpath generation is complete.
        break;
      default:
        // This will reset the component's state, as the initial value of
        // siemensResponse is null, and it will proceed to the following case block.
        setProgress(0);
        setDisplayMessage(undefined);
        setOpen(false);
    }
  }, [siemensResponse]);

  const abortExecution = useCallback(() => {
    if (abortEventId) {
      const abortEvent = new Event(abortEventId);
      window.dispatchEvent(abortEvent);
    }
  }, [abortEventId]);

  return (
    <>
      {siemensLoading && (
        <Backdrop sx={() => ({ color: "#fff", zIndex: 100 })} open={true}>
          {abortEventId && (
            <HtmlTooltip
              open={openToolTip}
              title={
                <div className="text-[12px] font-inter normal-case">
                  <div className="text-[14px] font-inter font-medium text-gray-700">
                    To cancel toolpath generation, click the cross icon.
                  </div>
                  <div className="flex flex-row w-full justify-between pt-4 items-center">
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={hideAbortToolTip}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setHideAbortToolTip(event.target.checked);
                              }}
                            />
                          }
                          label="Don't show again"
                          slotProps={{
                            typography: {
                              className: "text-[12px] text-gray-700 font-inter",
                            },
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <Button
                        size="sm"
                        className="px-[8px] py-2 font-semibold rounded-[4px] w-[150px] text-center bg-surface-default text-white hover:opacity-80 active:opacity-50"
                        onClick={() => {
                          setOpenToolTip(false);
                          if (hideAbortToolTip) {
                            //making call to update flag in userInfo
                            updateUserFlag(
                              "hideGenerateToolPathToolTip",
                              userInfo,
                              updateUser,
                              dispatch
                            );
                          }
                        }}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </div>
              }
              arrow
            >
              <IconButton
                onClick={abortExecution}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "rgb(0, 0, 0)",
                  backgroundColor: "rgb(255, 255, 255) !important",
                  borderRadius: "50%",
                }}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </HtmlTooltip>
          )}
          <Box sx={{ width: "440px", textAlign: "center" }}>
            <div className="pb-2">
              <CircularProgress color="inherit" />
            </div>
            <CustomLinearProgress variant="determinate" value={progress} />
            <div className="pt-2">{displayMessage}</div>
          </Box>
        </Backdrop>
      )}
      <ToolpathGenerationSuccessfulModal isOpen={open} setIsOpen={setOpen} />
    </>
  );
}
